import { createRouter, createWebHistory } from 'vue-router';

import {userService} from "../services";

//import { i18n } from "../i18n"

import {useIndexStore} from "../stores";
import {useAccountStore} from "../stores/account";

//Vue.use(VueRouter);

function encodeRoute(path) {
    let splitPath = path.substr(8).split("/");

    let route = '/:locale'
    for (let i in splitPath) {

        if (splitPath[i]) {
            if (splitPath[i].substr(0, 1) !== ':') {
                route += '/' + encodeURIComponent(splitPath[i])
            }
            else {
                route += '/' + splitPath[i]
            }
        }
    }

    return route
}

function addEncodedRoutes(routes) {
    let newRoutes = routes
    for (let i in routes) {
        let path = encodeRoute(routes[i])
        newRoutes.push(path)
    }

    return newRoutes
}

const locales = ['/cs/', '/de/', '/en/', '/es/', '/fi/', '/fr/', '/it/', '/ja/', '/nl/', '/ru/']

const routes = [
    {
        path: '/shop',
        alias: addEncodedRoutes([
            '/:locale/shop',
            '/:locale/obchod',
            '/:locale/Shop',
            '/:locale/tienda',
            '/:locale/kauppa',
            '/:locale/boutique',
            '/:locale/negozio',
            '/:locale/店舗',
            '/:locale/winkel',
            '/:locale/магазин',
        ]),
        name: 'shop',
        component: () => import('../components/Products.vue'),
        // beforeEnter: (to, from, next) => {
        //
        //     let newLocale = null
        //
        //     if (locales.includes(to.path.substr(0,4)) && to.path.substr(1,2) !== this.$i18n.locale) {
        //         newLocale = to.path.substr(1,2)
        //         if (newLocale) {
        //             this.$i18n.locale = newLocale
        //             useIndexStore.setLocale(newLocale)
        //         }
        //     }
        //
        //     if(this.$i18n.locale !== null && to.path.startsWith('/' + this.$i18n.locale) === false && (this.$i18n.locale !== 'en' || newLocale)) {
        //         router.push('/' + this.$i18n.locale + '/' + i18n.t('routes.shop')).catch(() => {})
        //     } else {
        //         next();
        //     }
        // }
    },
    {
        path: '/shop/search/remedies',
        alias: addEncodedRoutes([
            '/:locale/shop/search/remedies',
            '/:locale/obchod/hledání/léky',
            '/:locale/Shop/Suche/Heilmittel',
            '/:locale/tienda/buscar/remedios',
            '/:locale/kauppa/haku/Valmisteet',
            '/:locale/boutique/recherche/remèdes',
            '/:locale/negozio/ricerca/rimedi',
            '/:locale/店舗/検索/レメディ',
            '/:locale/winkel/zoeken/geneesmiddelen',
            '/:locale/магазин/магазин/лекарства'
        ]),
        name: 'remedies',
        component: () => import('../components/Remedies.vue'),
    },
    {
        path: '/shop/search/remedies/:letter',
        alias: addEncodedRoutes([
            '/:locale/shop/search/remedies/:letter',
            '/:locale/obchod/hledání/léky/:letter',
            '/:locale/Shop/Suche/Heilmittel/:letter',
            '/:locale/tienda/buscar/remedios/:letter',
            '/:locale/kauppa/haku/Valmisteet/:letter',
            '/:locale/boutique/recherche/remèdes/:letter',
            '/:locale/negozio/ricerca/rimedi/:letter',
            '/:locale/店舗/検索/レメディ/:letter',
            '/:locale/winkel/zoeken/geneesmiddelen/:letter',
            '/:locale/магазин/магазин/лекарства/:letter'
        ]),
        name: 'remedies-letter',
        component: () => import('../components/Remedies.vue'),
        props: true,
        // beforeEnter: (to, from, next) => {
        //
        //     let newLocale = null
        //
        //     if (locales.includes(to.path.substr(0,4)) && to.path.substr(1,2) !== this.$i18n.locale) {
        //         newLocale = to.path.substr(1,2)
        //         if (newLocale) {
        //             this.$i18n.locale = newLocale
        //             useIndexStore.setLocale(newLocale)
        //         }
        //     }
        //
        //     if(this.$i18n.locale !== null && to.path.startsWith('/' + this.$i18n.locale) === false && (this.$i18n.locale !== 'en' || newLocale)) {
        //         router.push('/' + this.$i18n.locale + '/' + i18n.t('routes.shop') + '/' + i18n.t('routes.search') + '/' + i18n.t('routes.remedies') + '/' + to.params.letter).catch(() => {})
        //     } else {
        //         next();
        //     }
        // }
    },
    {
        path: '/shop/search/remedies/form/:form',
        alias: addEncodedRoutes([
            '/:locale/shop/search/remedies/form/:form',
            '/:locale/obchod/hledání/léky/formulář/:form',
            '/:locale/Shop/Suche/Heilmittel/formular/:form',
            '/:locale/tienda/buscar/remedios/formulario/:form',
            '/:locale/kauppa/haku/Valmisteet/lomake/:form',
            '/:locale/boutique/recherche/remèdes/format/:form',
            '/:locale/negozio/ricerca/rimedi/modulo/:form',
            '/:locale/店舗/検索/レメディ/フォーム/:form',
            '/:locale/winkel/zoeken/geneesmiddelen/formaat/:form',
            '/:locale/магазин/магазин/лекарства/Форма/:form'
        ]),
        name: 'remedies-form',
        component: () => import('../components/Remedies.vue'),
        props: true,
        // beforeEnter: (to, from, next) => {
        //
        //     let newLocale = null
        //
        //     if (locales.includes(to.path.substr(0,4)) && to.path.substr(1,2) !== this.$i18n.locale) {
        //         newLocale = to.path.substr(1,2)
        //         if (newLocale) {
        //             this.$i18n.locale = newLocale
        //             useIndexStore.setLocale(newLocale)
        //         }
        //     }
        //
        //     if(this.$i18n.locale !== null && to.path.startsWith('/' + this.$i18n.locale) === false && (this.$i18n.locale !== 'en' || newLocale)) {
        //         router.push('/' + this.$i18n.locale + '/' + i18n.t('routes.shop') + '/' + i18n.t('routes.search') + '/' + i18n.t('routes.remedies') + '/' + i18n.t('routes.form') + '/' + to.params.form).catch(() => {})
        //     } else {
        //         next();
        //     }
        // }
    },
    {
        path: '/shop/search/remedies/taxonomy/:taxonomy',
        alias: addEncodedRoutes([
            '/:locale/shop/search/remedies/taxonomy/:taxonomy',
            '/:locale/obchod/hledání/léky/taxonomie/:taxonomy',
            '/:locale/Shop/Suche/Heilmittel/Taxonomie/:taxonomy',
            '/:locale/tienda/buscar/remedios/taxonomía/:taxonomy',
            '/:locale/kauppa/haku/Valmisteet/taksonomia/:taxonomy',
            '/:locale/boutique/recherche/remèdes/taxonomie/:taxonomy',
            '/:locale/negozio/ricerca/rimedi/tassonomia/:taxonomy',
            '/:locale/店舗/検索/レメディ/分類/:taxonomy',
            '/:locale/winkel/zoeken/geneesmiddelen/taxonomie/:taxonomy',
            '/:locale/магазин/магазин/лекарства/лекарства-из-категории/:taxonomy'
        ]),
        name: 'remedies-taxonomy',
        component: () => import('../components/Remedies.vue'),
        props: true,
        // beforeEnter: (to, from, next) => {
        //
        //     let newLocale = null
        //
        //     if (locales.includes(to.path.substr(0,4)) && to.path.substr(1,2) !== this.$i18n.locale) {
        //         newLocale = to.path.substr(1,2)
        //         if (newLocale) {
        //             this.$i18n.locale = newLocale
        //             useIndexStore.setLocale(newLocale)
        //         }
        //     }
        //
        //     if(this.$i18n.locale !== null && to.path.startsWith('/' + this.$i18n.locale) === false && (this.$i18n.locale !== 'en' || newLocale)) {
        //         router.push('/' + this.$i18n.locale + '/' + i18n.t('routes.shop') + '/' + i18n.t('routes.search') + '/' + i18n.t('routes.remedies') + '/' + i18n.t('routes.taxonomy') + '/' + to.params.taxonomy).catch(() => {})
        //     } else {
        //         next();
        //     }
        // }
    },
    {
        path: '/remedies/remedy-buying-guide',
        alias: addEncodedRoutes([
            '/:locale/remedies/remedy-buying-guide',
            '/:locale/léky/průvodce-nákupem-léků',
            '/:locale/Heilmittel/wegweiser-zum-kauf-von-heilmitteln',
            '/:locale/remedios/guía-para-la-compra-de-remedios',
            '/:locale/Valmisteet/hoitojen-osto-opas',
            '/:locale/remèdes/guide-d-achat-de-médicament-homéopathique',
            '/:locale/rimedi/guida-all-acquisto-dei-rimedi',
            '/:locale/レメディ/レメディご購入ガイド',
            '/:locale/geneesmiddelen/koopgids-voor-geneesmiddelen',
            '/:locale/лекарства/pуководство-по-покупке-лекарств',
        ]),
        name: 'remedy-buying-guide',
        component: () => import('../components/RemedyBuyingGuide.vue'),
    },
    {
        path: '/remedies/:slug',
        alias: addEncodedRoutes([
            '/:locale/remedies/:slug',
            '/:locale/léky/:slug',
            '/:locale/Heilmittel/:slug',
            '/:locale/remedios/:slug',
            '/:locale/Valmisteet/:slug',
            '/:locale/remèdes/:slug',
            '/:locale/rimedi/:slug',
            '/:locale/レメディ/:slug',
            '/:locale/geneesmiddelen/:slug',
            '/:locale/лекарства/:slug',
        ]),
        name: 'remedies-content',
        component: () => import('../components/Content.vue'),
        props: true,
        // beforeEnter: (to, from, next) => {
        //
        //     let newLocale = null
        //
        //     if (locales.includes(to.path.substr(0,4)) && to.path.substr(1,2) !== this.$i18n.locale) {
        //         newLocale = to.path.substr(1,2)
        //         if (newLocale) {
        //             this.$i18n.locale = newLocale
        //             useIndexStore.setLocale(newLocale)
        //         }
        //     }
        //
        //     if(this.$i18n.locale !== null && to.path.startsWith('/' + this.$i18n.locale) === false && (this.$i18n.locale !== 'en' || newLocale)) {
        //         router.push('/' + this.$i18n.locale + '/' + i18n.t('routes.remedies') + '/' + to.params.slug).catch(() => {})
        //     } else {
        //         next();
        //     }
        // },
    },
    {
        path: '/shop/search/brand/:slug',
        alias: addEncodedRoutes([
            '/:locale/shop/search/brand/:slug',
            '/:locale/obchod/hledání/znacka/:slug',
            '/:locale/Shop/Suche/Marke/:slug',
            '/:locale/tienda/buscar/marca/:slug',
            '/:locale/kauppa/haku/tuotemerkki/:slug',
            '/:locale/boutique/recherche/marque/:slug',
            '/:locale/negozio/ricerca/marca/:slug',
            '/:locale/店舗/検索/ブランド/:slug',
            '/:locale/winkel/zoeken/merk/:slug',
            '/:locale/магазин/магазин/продукция-бренда/:slug',
        ]),
        name: 'brand',
        component: () => import('../components/Brand.vue'),
        props: true,
        // beforeEnter: (to, from, next) => {
        //
        //     let newLocale = null
        //
        //     if (locales.includes(to.path.substr(0,4)) && to.path.substr(1,2) !== this.$i18n.locale) {
        //         newLocale = to.path.substr(1,2)
        //         if (newLocale) {
        //             this.$i18n.locale = newLocale
        //             useIndexStore.setLocale(newLocale)
        //         }
        //     }
        //
        //     if(this.$i18n.locale !== null && to.path.startsWith('/' + this.$i18n.locale) === false && (this.$i18n.locale !== 'en' || newLocale)) {
        //         router.push('/' + this.$i18n.locale + '/' + i18n.t('routes.shop') + '/' + i18n.t('routes.search') + '/' + i18n.t('routes.brand') + '/' + to.params.slug).catch(() => {})
        //     } else {
        //         next();
        //     }
        // }
    },
    {
        path: '/shop/checkout',
        alias: addEncodedRoutes([
            '/:locale/shop/checkout',
            '/:locale/obchod/pokladna',
            '/:locale/Shop/zur-kasse',
            '/:locale/tienda/pagar',
            '/:locale/kauppa/kassalle',
            '/:locale/boutique/paiement',
            '/:locale/negozio/cassa',
            '/:locale/店舗/ご清算',
            '/:locale/winkel/kassa',
            '/:locale/магазин/оформление-заказа',
        ]),
        name: 'checkout',
        component: () => import('../components/Checkout.vue'),
    },
    {
        path: '/shop/search',
        alias: addEncodedRoutes([
            '/:locale/shop/search',
            '/:locale/obchod/hledání',
            '/:locale/Shop/Suche',
            '/:locale/tienda/buscar',
            '/:locale/kauppa/haku',
            '/:locale/boutique/recherche',
            '/:locale/negozio/ricerca',
            '/:locale/店舗/検索',
            '/:locale/winkel/zoeken',
            '/:locale/магазин/магазин',
        ]),
        name: 'search',
        component: () => import('../components/Products.vue'),
        props: true,
        // beforeEnter: (to, from, next) => {
        //
        //     let newLocale = null
        //
        //     if (locales.includes(to.path.substr(0,4)) && to.path.substr(1,2) !== this.$i18n.locale) {
        //         newLocale = to.path.substr(1,2)
        //         if (newLocale) {
        //             this.$i18n.locale = newLocale
        //             useIndexStore.setLocale(newLocale)
        //         }
        //     }
        //
        //     if(this.$i18n.locale !== null && to.path.startsWith('/' + this.$i18n.locale) === false && (this.$i18n.locale !== 'en' || newLocale)) {
        //         router.push('/' + this.$i18n.locale + '/' + i18n.t('routes.shop') + '/' + i18n.t('routes.search')).catch(() => {})
        //     } else {
        //         next();
        //     }
        // }
    },
    {
        path: '/shop/:slug',
        alias: addEncodedRoutes([
            '/:locale/shop/:slug',
            '/:locale/obchod/:slug',
            '/:locale/Shop/:slug',
            '/:locale/tienda/:slug',
            '/:locale/kauppa/:slug',
            '/:locale/boutique/:slug',
            '/:locale/negozio/:slug',
            '/:locale/店舗/:slug',
            '/:locale/winkel/:slug',
            '/:locale/магазин/:slug',
        ]),
        name: 'product',
        component: () => import('../components/Product.vue'),
        props: true,
        // beforeEnter: (to, from, next) => {
        //
        //     let newLocale = null
        //
        //     if (locales.includes(to.path.substr(0,4)) && to.path.substr(1,2) !== this.$i18n.locale) {
        //         newLocale = to.path.substr(1,2)
        //         if (newLocale) {
        //             this.$i18n.locale = newLocale
        //             useIndexStore.setLocale(newLocale)
        //         }
        //     }
        //
        //     if(this.$i18n.locale !== null && to.path.startsWith('/' + this.$i18n.locale) === false && (this.$i18n.locale !== 'en' || newLocale)) {
        //         router.push('/' + this.$i18n.locale + '/' + i18n.t('routes.shop') + '/' + to.params.slug).catch(() => {})
        //     } else {
        //         next();
        //     }
        // }
    },
    {
        path: '/shop/search/category/:category',
        alias: addEncodedRoutes([
            '/:locale/shop/search/category/:category',
            '/:locale/obchod/hledání/kategorie/:category',
            '/:locale/Shop/Suche/Kategorie/:category',
            '/:locale/tienda/buscar/categoría/:category',
            '/:locale/kauppa/haku/luokka/:category',
            '/:locale/boutique/recherche/catégorie/:category',
            '/:locale/negozio/ricerca/categoria/:category',
            '/:locale/店舗/検索/カテゴリー/:category',
            '/:locale/winkel/zoeken/categorie/:category',
            '/:locale/магазин/магазин/категория/:category',
        ]),
        name: 'category',
        component: () => import('../components/Products.vue'),
        props: true,
        // beforeEnter: (to, from, next) => {
        //
        //     let newLocale = null
        //
        //     if (locales.includes(to.path.substr(0,4)) && to.path.substr(1,2) !== this.$i18n.locale) {
        //         newLocale = to.path.substr(1,2)
        //         if (newLocale) {
        //             this.$i18n.locale = newLocale
        //             useIndexStore.setLocale(newLocale)
        //         }
        //     }
        //
        //     console.log(this.$i18n.locale)
        //     if(this.$i18n.locale !== null && to.path.startsWith('/' + this.$i18n.locale) === false && (this.$i18n.locale !== 'en' || newLocale)) {
        //         router.push('/' + this.$i18n.locale + '/' + i18n.t('routes.shop') + '/' + i18n.t('routes.search') + '/' + i18n.t('routes.category') + '/' + i18n.t('routes.' + to.params.category).replace(/ /g, '-').toLowerCase()).catch(() => {})
        //     } else {
        //         next();
        //     }
        // }
    },
    {
        path: '/shop/search/category/:category/:subCategory',
        alias: addEncodedRoutes([
            '/:locale/shop/search/category/:category/:subCategory',
            '/:locale/obchod/hledání/kategorie/:category/:subCategory',
            '/:locale/Shop/Suche/Kategorie/:category/:subCategory',
            '/:locale/tienda/buscar/categoría/:category/:subCategory',
            '/:locale/kauppa/haku/luokka/:category/:subCategory',
            '/:locale/boutique/recherche/catégorie/:category/:subCategory',
            '/:locale/negozio/ricerca/categoria/:category/:subCategory',
            '/:locale/店舗/検索/カテゴリー/:category/:subCategory',
            '/:locale/winkel/zoeken/categorie/:category/:subCategory',
            '/:locale/магазин/магазин/категория/:category/:subCategory',
        ]),
        name: 'sub-category',
        component: () => import('../components/Products.vue'),
        props: true,
        // beforeEnter: (to, from, next) => {
        //
        //     let newLocale = null
        //
        //     if (locales.includes(to.path.substr(0,4)) && to.path.substr(1,2) !== this.$i18n.locale) {
        //         newLocale = to.path.substr(1,2)
        //         if (newLocale) {
        //             this.$i18n.locale = newLocale
        //             useIndexStore.setLocale(newLocale)
        //         }
        //     }
        //
        //     if(this.$i18n.locale !== null && to.path.startsWith('/' + this.$i18n.locale) === false && (this.$i18n.locale !== 'en' || newLocale)) {
        //         router.push('/' + this.$i18n.locale + '/' + i18n.t('routes.shop') + '/' + i18n.t('routes.search') + '/' + i18n.t('routes.category') + '/' + i18n.t('routes.' + to.params.category).replace(/ /g, '-').toLowerCase() + '/' + i18n.t('routes.' + to.params.subCategory).replace(/ /g, '-').toLowerCase()).catch(() => {})
        //     } else {
        //         next();
        //     }
        // }
    },
    {
        path: '/shop/search/books',
        alias: addEncodedRoutes([
            '/:locale/shop/search/books',
            '/:locale/obchod/hledání/knihy',
            '/:locale/winkel/zoeken/boeken',
            '/:locale/kauppa/haku/kirjat',
            '/:locale/boutique/recherche/livres',
            '/:locale/Shop/Suche/bücher',
            '/:locale/negozio/ricerca/libri',
            '/:locale/店舗/検索/書籍',
            '/:locale/магазин/магазин/книги',
            '/:locale/tienda/buscar/libros'
        ]),
        name: 'books',
        component: () => import('../components/Books.vue'),
        // beforeEnter: (to, from, next) => {
        //
        //     let newLocale = null
        //
        //     if (locales.includes(to.path.substr(0,4)) && to.path.substr(1,2) !== this.$i18n.locale) {
        //         newLocale = to.path.substr(1,2)
        //         if (newLocale) {
        //             this.$i18n.locale = newLocale
        //             useIndexStore.setLocale(newLocale)
        //         }
        //     }
        //
        //     if(this.$i18n.locale !== null && to.path.startsWith('/' + this.$i18n.locale) === false && (this.$i18n.locale !== 'en' || newLocale)) {
        //         router.push('/' + this.$i18n.locale + '/' + i18n.t('routes.shop') + '/' + i18n.t('routes.search') + '/' + i18n.t('routes.books')).catch(() => {})
        //     } else {
        //         next();
        //     }
        // }
    },
    {
        path: '/shop/search/books/:topicSlug',
        alias: addEncodedRoutes([
            '/:locale/shop/search/books/:topicSlug',
            '/:locale/obchod/hledání/knihy/:topicSlug',
            '/:locale/winkel/zoeken/boeken/:topicSlug',
            '/:locale/kauppa/haku/kirjat/:topicSlug',
            '/:locale/boutique/recherche/livres/:topicSlug',
            '/:locale/Shop/Suche/bücher/:topicSlug',
            '/:locale/negozio/ricerca/libri/:topicSlug',
            '/:locale/店舗/検索/書籍/:topicSlug',
            '/:locale/магазин/магазин/книги/:topicSlug',
            '/:locale/tienda/buscar/libros/:topicSlug'
        ]),
        name: 'books-topic',
        component: () => import('../components/Books.vue'),
        props: true,
        // beforeEnter: (to, from, next) => {
        //
        //     let newLocale = null
        //
        //     if (locales.includes(to.path.substr(0,4)) && to.path.substr(1,2) !== this.$i18n.locale) {
        //         newLocale = to.path.substr(1,2)
        //         if (newLocale) {
        //             this.$i18n.locale = newLocale
        //             useIndexStore.setLocale(newLocale)
        //         }
        //     }
        //
        //     if(this.$i18n.locale !== null && to.path.startsWith('/' + this.$i18n.locale) === false && (this.$i18n.locale !== 'en' || newLocale)) {
        //         router.push('/' + this.$i18n.locale + '/' + i18n.t('routes.shop') + '/' + i18n.t('routes.search') + '/' + i18n.t('routes.books') + '/' + to.params.topicSlug).catch(() => {})
        //     } else {
        //         next();
        //     }
        // }
    },
    {
        path: '/shop/search/books/:topicSlug/:subTopicSlug',
        alias: addEncodedRoutes([
            '/:locale/shop/search/books/:topicSlug/:subTopicSlug',
            '/:locale/obchod/hledání/knihy/:topicSlug/:subTopicSlug',
            '/:locale/winkel/zoeken/boeken/:topicSlug/:subTopicSlug',
            '/:locale/kauppa/haku/kirjat/:topicSlug/:subTopicSlug',
            '/:locale/boutique/recherche/livres/:topicSlug/:subTopicSlug',
            '/:locale/Shop/Suche/bücher/:topicSlug/:subTopicSlug',
            '/:locale/negozio/ricerca/libri/:topicSlug/:subTopicSlug',
            '/:locale/店舗/検索/書籍/:topicSlug/:subTopicSlug',
            '/:locale/магазин/магазин/книги/:topicSlug/:subTopicSlug',
            '/:locale/tienda/buscar/libros/:topicSlug/:subTopicSlug'
        ]),
        name: 'books-sub-topic',
        component: () => import('../components/Books.vue'),
        props: true,
        // beforeEnter: (to, from, next) => {
        //
        //     let newLocale = null
        //
        //     if (locales.includes(to.path.substr(0,4)) && to.path.substr(1,2) !== this.$i18n.locale) {
        //         newLocale = to.path.substr(1,2)
        //         if (newLocale) {
        //             this.$i18n.locale = newLocale
        //             useIndexStore.setLocale(newLocale)
        //         }
        //     }
        //
        //     if(this.$i18n.locale !== null && to.path.startsWith('/' + this.$i18n.locale) === false && (this.$i18n.locale !== 'en' || newLocale)) {
        //         router.push('/' + this.$i18n.locale + '/' + i18n.t('routes.shop') + '/' + i18n.t('routes.search') + '/' + i18n.t('routes.books') + '/' + to.params.topicSlug + '/' + to.params.subTopicSlug).catch(() => {})
        //     } else {
        //         next();
        //     }
        // }
    },
    {
        path: '/shop/search/popular/:popular',
        alias: addEncodedRoutes([
            '/:locale/shop/search/popular/:popular',
            '/:locale/obchod/hledání/oblíbené/:popular',
            '/:locale/Shop/Suche/Beliebt/:popular',
            '/:locale/tienda/buscar/popular/:popular',
            '/:locale/kauppa/haku/suositut/:popular',
            '/:locale/boutique/recherche/populaire/:popular',
            '/:locale/negozio/ricerca/popolari/:popular',
            '/:locale/店舗/検索/人気/:popular',
            '/:locale/winkel/zoeken/populair/:popular',
            '/:locale/магазин/магазин/популярное-в-разделе/:popular',
        ]),
        name: 'popular',
        component: () => import('../components/Products.vue'),
        props: true,
        // beforeEnter: (to, from, next) => {
        //
        //     let newLocale = null
        //
        //     if (locales.includes(to.path.substr(0,4)) && to.path.substr(1,2) !== this.$i18n.locale) {
        //         newLocale = to.path.substr(1,2)
        //         if (newLocale) {
        //             this.$i18n.locale = newLocale
        //             useIndexStore.setLocale(newLocale)
        //         }
        //     }
        //
        //     if(this.$i18n.locale !== null && to.path.startsWith('/' + this.$i18n.locale) === false && (this.$i18n.locale !== 'en' || newLocale)) {
        //         router.push('/' + this.$i18n.locale + '/' + i18n.t('routes.shop') + '/' + i18n.t('routes.search') + '/' + i18n.t('routes.popular') + '/' + to.params.popular).catch(() => {})
        //     } else {
        //         next();
        //     }
        // }
    },
    {
        path: '/shop/search/latest/:latest',
        alias: addEncodedRoutes([
            '/:locale/shop/search/latest/:latest',
            '/:locale/obchod/hledání/nejnovější/:latest',
            '/:locale/Shop/Suche/Letzte/:latest',
            '/:locale/tienda/buscar/últimos/:latest',
            '/:locale/kauppa/haku/viimeisimmät/:latest',
            '/:locale/boutique/recherche/récente/:latest',
            '/:locale/negozio/ricerca/recenti/:latest',
            '/:locale/店舗/検索/最新/:latest',
            '/:locale/winkel/zoeken/recent/:latest',
            '/:locale/магазин/магазин/новинки-в-разделе/:latest',
        ]),
        name: 'latest',
        component: () => import('../components/Products.vue'),
        props: true,
        // beforeEnter: (to, from, next) => {
        //
        //     let newLocale = null
        //
        //     if (locales.includes(to.path.substr(0,4)) && to.path.substr(1,2) !== this.$i18n.locale) {
        //         newLocale = to.path.substr(1,2)
        //         if (newLocale) {
        //             this.$i18n.locale = newLocale
        //             useIndexStore.setLocale(newLocale)
        //         }
        //     }
        //
        //     if(this.$i18n.locale !== null && to.path.startsWith('/' + this.$i18n.locale) === false && (this.$i18n.locale !== 'en' || newLocale)) {
        //         router.push('/' + this.$i18n.locale + '/' + i18n.t('routes.shop') + '/' + i18n.t('routes.search') + '/' + i18n.t('routes.latest') + '/' + to.params.latest).catch(() => {})
        //     } else {
        //         next();
        //     }
        // }
    },
    {
        path: '/collections/:slug',
        alias: addEncodedRoutes([
            '/:locale/collections/:slug',
            '/:locale/sbírky/:slug',
            '/:locale/kollektionen/:slug',
            '/:locale/colecciones/:slug',
            '/:locale/kokoelmat/:slug',
            '/:locale/collezioni/:slug',
            '/:locale/コレクション/:slug',
            '/:locale/collecties/:slug',
            '/:locale/наборы/:slug',
        ]),
        name: 'collection',
        component: () => import('../components/Collection.vue'),
        props: true,
        // beforeEnter: (to, from, next) => {
        //
        //     let newLocale = null
        //
        //     if (locales.includes(to.path.substr(0,4)) && to.path.substr(1,2) !== this.$i18n.locale) {
        //         newLocale = to.path.substr(1,2)
        //         if (newLocale) {
        //             this.$i18n.locale = newLocale
        //             useIndexStore.setLocale(newLocale)
        //         }
        //     }
        //
        //     if(this.$i18n.locale !== null && to.path.startsWith('/' + this.$i18n.locale) === false && (this.$i18n.locale !== 'en' || newLocale)) {
        //         router.push('/' + this.$i18n.locale + '/' + i18n.t('routes.collections') + '/' + to.params.slug).catch(() => {})
        //     } else {
        //         next();
        //     }
        // }
    },
    {
        path: '/discount/:slug',
        alias: addEncodedRoutes([
            '/:locale/discount/:slug',
            '/:locale/sleva/:slug',
            '/:locale/Rabatt/:slug',
            '/:locale/descuento/:slug',
            '/:locale/alennus/:slug',
            '/:locale/promotion/:slug',
            '/:locale/sconto/:slug',
            '/:locale/割引/:slug',
            '/:locale/korting/:slug',
            '/:locale/скидка/:slug'
        ]),
        name: 'discount',
        component: () => import('../components/Discount.vue'),
        props: true,
        // beforeEnter: (to, from, next) => {
        //
        //     let newLocale = null
        //
        //     if (locales.includes(to.path.substr(0,4)) && to.path.substr(1,2) !== this.$i18n.locale) {
        //         newLocale = to.path.substr(1,2)
        //         if (newLocale) {
        //             this.$i18n.locale = newLocale
        //             useIndexStore.setLocale(newLocale)
        //         }
        //     }
        //
        //     if(this.$i18n.locale !== null && to.path.startsWith('/' + this.$i18n.locale) === false && (this.$i18n.locale !== 'en' || newLocale)) {
        //         router.push('/' + this.$i18n.locale + '/' + i18n.t('routes.discount') + '/' + to.params.slug).catch(() => {})
        //     } else {
        //         next();
        //     }
        // }
    },
    {
        path: '/contact/enquiry',
        alias: addEncodedRoutes([
            '/:locale/kontakt/dotaz',
            '/:locale/Kontakt/anfrage',
            '/:locale/contact/enquiry',
            '/:locale/contacto/consulta',
            '/:locale/yhteystiedot/tiedustelu',
            '/:locale/contact/demande',
            '/:locale/contatti/richiesta',
            '/:locale/連絡先情報/お問い合わせ',
            '/:locale/contact/vraag',
            '/:locale/контакты/запрос',
        ]),
        name: 'enquiry',
        component: () => import('../components/SecureEnquiry.vue'),
        // beforeEnter: (to, from, next) => {
        //
        //     let newLocale = null
        //
        //     if (locales.includes(to.path.substr(0,4)) && to.path.substr(1,2) !== this.$i18n.locale) {
        //         newLocale = to.path.substr(1,2)
        //         if (newLocale) {
        //             this.$i18n.locale = newLocale
        //             useIndexStore.setLocale(newLocale)
        //         }
        //     }
        //
        //     if(this.$i18n.locale !== null && to.path.startsWith('/' + this.$i18n.locale) === false && (this.$i18n.locale !== 'en' || newLocale)) {
        //         router.push('/' + this.$i18n.locale + '/' + i18n.t('routes.contact') + '/' + i18n.t('routes.enquiry')).catch(() => {})
        //     } else {
        //         next();
        //     }
        // }
    },
    {
        path: '/contact/frequently-asked-questions',
        alias: addEncodedRoutes([
            '/:locale/Kontakt/häufig-gestellte-fragen',
            '/:locale/kontakt/casto-kladene-otazky',
            '/:locale/contact/frequently-asked-questions',
            '/:locale/contacto/preguntas-frequentes',
            '/:locale/yhteystiedot/usein-kysytyt-kysymykset',
            '/:locale/contact/foire-aux-questions',
            '/:locale/contatti/domande-frequenti',
            '/:locale/連絡先情報/よくあるご質問',
            '/:locale/contact/veelgestelde-vragen',
            '/:locale/контакты/часто-задаваемые-вопросы',
        ]),
        name: 'faqs',
        component: () => import('../components/Faqs.vue'),
        // beforeEnter: (to, from, next) => {
        //
        //     let newLocale = null
        //
        //     if (locales.includes(to.path.substr(0,4)) && to.path.substr(1,2) !== this.$i18n.locale) {
        //         newLocale = to.path.substr(1,2)
        //         if (newLocale) {
        //             this.$i18n.locale = newLocale
        //             useIndexStore.setLocale(newLocale)
        //         }
        //     }
        //
        //     if(this.$i18n.locale !== null && to.path.startsWith('/' + this.$i18n.locale) === false && (this.$i18n.locale !== 'en' || newLocale)) {
        //         router.push('/' + this.$i18n.locale + '/' + i18n.t('routes.contact') + '/' + i18n.t('routes.frequently-asked-questions')).catch(() => {})
        //     } else {
        //         next();
        //     }
        // },
    },
    {
        path: '/contact/:slug',
        alias: addEncodedRoutes([
            '/:locale/kontakt/:slug',
            '/:locale/Kontakt/:slug',
            '/:locale/contact/:slug',
            '/:locale/contacto/:slug',
            '/:locale/yhteystiedot/:slug',
            '/:locale/contact/:slug',
            '/:locale/contatti/:slug',
            '/:locale/連絡先情報/:slug',
            '/:locale/contact/:slug',
            '/:locale/контакты/:slug',
        ]),
        name: 'contact-content',
        component: () => import('../components/Content.vue'),
        props: true,
        // beforeEnter: (to, from, next) => {
        //
        //     let newLocale = null
        //
        //     if (locales.includes(to.path.substr(0,4)) && to.path.substr(1,2) !== this.$i18n.locale) {
        //         newLocale = to.path.substr(1,2)
        //         if (newLocale) {
        //             this.$i18n.locale = newLocale
        //             useIndexStore.setLocale(newLocale)
        //         }
        //     }
        //
        //     if(this.$i18n.locale !== null && to.path.startsWith('/' + this.$i18n.locale) === false && (this.$i18n.locale !== 'en' || newLocale)) {
        //         router.push('/' + this.$i18n.locale + '/' + i18n.t('routes.contact') + '/' + to.params.slug).catch(() => {})
        //     } else {
        //         next();
        //     }
        // },
    },
    {
        path: '/contact',
        alias: addEncodedRoutes([
            '/:locale/kontakt',
            '/:locale/Kontakt',
            '/:locale/contact',
            '/:locale/contacto',
            '/:locale/yhteystiedot',
            '/:locale/contatti',
            '/:locale/連絡先情報',
            '/:locale/контакты',
        ]),
        name: 'contact',
        component: () => import('../components/Contact.vue'),
        // beforeEnter: (to, from, next) => {
        //
        //     let newLocale = null
        //
        //     if (locales.includes(to.path.substr(0,4)) && to.path.substr(1,2) !== this.$i18n.locale) {
        //         newLocale = to.path.substr(1,2)
        //         if (newLocale) {
        //             this.$i18n.locale = newLocale
        //             useIndexStore.setLocale(newLocale)
        //         }
        //     }
        //
        //     if(this.$i18n.locale !== null && to.path.startsWith('/' + this.$i18n.locale) === false && (this.$i18n.locale !== 'en' || newLocale)) {
        //         router.push('/' + this.$i18n.locale + '/' + i18n.t('routes.contact')).catch(() => {})
        //     } else {
        //         next();
        //     }
        // }
    },
    {
        path: '/terms-and-conditions',
        alias: addEncodedRoutes([
            '/:locale/terms-and-conditions',
            '/:locale/obchodni-podminky',
            '/:locale/allgemeine-geschäftsbedingungen',
            '/:locale/términos-y-condiciones',
            '/:locale/käyttöehdot',
            '/:locale/conditions-d-utilisation',
            '/:locale/termini-e-condizioni',
            '/:locale/利用規約',
            '/:locale/algemene-voorwaarden',
            '/:locale/условия-обслуживания',
        ]),
        name: 'terms',
        component: () => import('../components/Terms.vue'),
        // beforeEnter: (to, from, next) => {
        //
        //     let newLocale = null
        //
        //     if (locales.includes(to.path.substr(0,4)) && to.path.substr(1,2) !== this.$i18n.locale) {
        //         newLocale = to.path.substr(1,2)
        //         if (newLocale) {
        //             this.$i18n.locale = newLocale
        //             useIndexStore.setLocale(newLocale)
        //         }
        //     }
        //
        //     if(this.$i18n.locale !== null && to.path.startsWith('/' + this.$i18n.locale) === false && (this.$i18n.locale !== 'en' || newLocale)) {
        //         router.push('/' + this.$i18n.locale + '/' + i18n.t('routes.terms-and-conditions')).catch(() => {})
        //     } else {
        //         next();
        //     }
        // }
    },
    {
        path: '/register',
        alias: addEncodedRoutes([
            '/:locale/register',
            '/:locale/registrace',
            '/:locale/Registrieren',
            '/:locale/registro',
            '/:locale/rekisteröidy',
            '/:locale/inscription',
            '/:locale/registrati',
            '/:locale/登録',
            '/:locale/registreren',
            '/:locale/регистрация'
        ]),
        name: 'register',
        component: () => import('../components/Register.vue'),
        // beforeEnter: (to, from, next) => {
        //
        //     let newLocale = null
        //
        //     if (locales.includes(to.path.substr(0,4)) && to.path.substr(1,2) !== this.$i18n.locale) {
        //         newLocale = to.path.substr(1,2)
        //         if (newLocale) {
        //             this.$i18n.locale = newLocale
        //             useIndexStore.setLocale(newLocale)
        //         }
        //     }
        //
        //     if(this.$i18n.locale !== null && to.path.startsWith('/' + this.$i18n.locale) === false && (this.$i18n.locale !== 'en' || newLocale)) {
        //         router.push('/' + this.$i18n.locale + '/' + i18n.t('routes.register')).catch(() => {})
        //     } else {
        //         next();
        //     }
        // }
    },
    {
        path: '/login',
        alias: addEncodedRoutes([
            '/:locale/login',
            '/:locale/prihlaseni',
            '/:locale/Anmelden',
            '/:locale/iniciar-sesión',
            '/:locale/kirjaudu-sisään',
            '/:locale/connexion',
            '/:locale/accedi',
            '/:locale/ログイン',
            '/:locale/aanmelden',
            '/:locale/вход'
        ]),
        name: 'login',
        component: () => import('../components/Login.vue')
    },
    {
        path: '/logout',
        alias: '/:locale/logout',
        name: 'logout',
        beforeEnter: () => {
            let user = useAccountStore().user

            if(user) {
                userService.logout().then(() => {
                    // if(this.$i18n.locale !== null) {
                    //     router.push('/' + this.$i18n.locale).catch(() => {})
                    // } else {
                    //     router.push('/').catch(() => {})
                    // }
                    router.push('/').catch(() => {})
                })
            } else {
                //let locale = locale;
                // if(this.$i18n.locale !== null) {
                //     router.push('/' + this.$i18n.locale).catch(() => {})
                // } else {
                //     router.push('/').catch(() => {})
                // }
                router.push('/').catch(() => {})
            }
        }
    },
    {
        path: '/reset-password',
        alias: addEncodedRoutes([
            '/:locale/reset-password',
            '/:locale/obnoveni-hesla',
            '/:locale/passwort-zurücksetzen',
            '/:locale/restablecer-contraseña',
            '/:locale/aseta-uusi-salasana',
            '/:locale/réinitialiser-motdepasse',
            '/:locale/reimpostazione-password',
            '/:locale/パスワードのリセット',
            '/:locale/wachtwoord-resetten',
            '/:locale/сброс-пароля'
        ]),
        name: 'reset-password',
        component: () => import('../components/ResetPassword.vue'),
        // beforeEnter: (to, from, next) => {
        //
        //     let newLocale = null
        //
        //     if (locales.includes(to.path.substr(0,4)) && to.path.substr(1,2) !== this.$i18n.locale) {
        //         newLocale = to.path.substr(1,2)
        //         if (newLocale) {
        //             this.$i18n.locale = newLocale
        //             useIndexStore.setLocale(newLocale)
        //         }
        //     }
        //
        //     if(this.$i18n.locale !== null && to.path.startsWith('/' + this.$i18n.locale) === false && (this.$i18n.locale !== 'en' || newLocale)) {
        //         router.push('/' + this.$i18n.locale + '/' + i18n.t('routes.reset-password')).catch(() => {})
        //     } else {
        //         next();
        //     }
        // }
    },
    {
        path: '/reset-password/:token',
        alias: addEncodedRoutes([
            '/:locale/reset-password/:token',
            '/:locale/obnoveni-hesla/:token',
            '/:locale/passwort-zurücksetzen/:token',
            '/:locale/restablecer-contraseña/:token',
            '/:locale/aseta-uusi-salasana/:token',
            '/:locale/réinitialiser-motdepasse/:token',
            '/:locale/reimpostazione-password/:token',
            '/:locale/パスワードのリセット/:token',
            '/:locale/wachtwoord-resetten/:token',
            '/:locale/сброс-пароля/:token'
        ]),
        name: 'reset-password-form',
        component: () => import('../components/ResetPasswordForm.vue'),
        // beforeEnter: (to, from, next) => {
        //
        //     let newLocale = null
        //
        //     if (locales.includes(to.path.substr(0,4)) && to.path.substr(1,2) !== this.$i18n.locale) {
        //         newLocale = to.path.substr(1,2)
        //         if (newLocale) {
        //             this.$i18n.locale = newLocale
        //             useIndexStore.setLocale(newLocale)
        //         }
        //     }
        //
        //     if(this.$i18n.locale !== null && to.path.startsWith('/' + this.$i18n.locale) === false && (this.$i18n.locale !== 'en' || newLocale)) {
        //         router.push('/' + this.$i18n.locale + '/' + i18n.t('routes.reset-password') + '/' + to.params.token).catch(() => {})
        //     } else {
        //         next();
        //     }
        // },
        meta: {
            auth: false
        }
    },
    {
        path: '/verify',
        name: 'verify',
        component: () => import('../components/Verify.vue')
    },
    {
        path: '/verified',
        name: 'verified',
        component: () => import('../components/Verified.vue')
    },
    {
        path: '/account',
        alias: addEncodedRoutes([
            '/:locale/account',
            '/:locale/účet',
            '/:locale/konto',
            '/:locale/cuenta',
            '/:locale/tili',
            '/:locale/compte',
            '/:locale/アカウント',
            '/:locale/учетная-запись'
        ]),
        name: 'account',
        component: () => import('../components/Account.vue'),
        // beforeEnter: (to, from, next) => {
        //
        //     let newLocale = null
        //
        //     if (locales.includes(to.path.substr(0,4)) && to.path.substr(1,2) !== this.$i18n.locale) {
        //         newLocale = to.path.substr(1,2)
        //         if (newLocale) {
        //             this.$i18n.locale = newLocale
        //             useIndexStore.setLocale(newLocale)
        //         }
        //     }
        //
        //
        //     let user = useAccountStore().user()
        //     let authenticated = useIndexStore().isAuthenticated()
        //
        //     if(!authenticated) {
        //         router.push('/login').catch(() => {})
        //     }
        //     else if(user && user.email_verified_at == null) {
        //         router.push('/verify').catch(() => {})
        //     }
        //     else if(this.$i18n.locale !== null && to.path.startsWith('/' + this.$i18n.locale) === false && (this.$i18n.locale !== 'en' || newLocale)) {
        //         router.push('/' + this.$i18n.locale + '/' + i18n.t('routes.account')).catch(() => {})
        //     } else {
        //         next();
        //     }
        // }
    },
    {
        path: '/shop/checkout/success',
        alias: '/:locale/shop/checkout/success',
        name: 'success',
        component: () => import('../components/Success.vue')
    },
    {
        path: '/about-us/download',
        alias: addEncodedRoutes([
            '/:locale/about-us/download',
            '/:locale/o-nas/stáhnout',
            '/:locale/Über-uns/download',
            '/:locale/quiénes-somos/descargas',
            '/:locale/tietoja-meistä/lataa',
            '/:locale/qui-sommes-nous/téléchargement',
            '/:locale/chi-siamo/download',
            '/:locale/当社について/ダウンロード',
            '/:locale/over-ons/download',
            '/:locale/о-нас/загрузки'
        ]),
        name: 'downloads',
        component: () => import('../components/Downloads.vue'),
        props: true,
        // beforeEnter: (to, from, next) => {
        //
        //     let newLocale = null
        //
        //     if (locales.includes(to.path.substr(0,4)) && to.path.substr(1,2) !== this.$i18n.locale) {
        //         newLocale = to.path.substr(1,2)
        //         if (newLocale) {
        //             this.$i18n.locale = newLocale
        //             useIndexStore.setLocale(newLocale)
        //         }
        //     }
        //
        //     if(this.$i18n.locale !== null && to.path.startsWith('/' + this.$i18n.locale) === false && (this.$i18n.locale !== 'en' || newLocale)) {
        //         router.push('/' + this.$i18n.locale + '/' + i18n.t('routes.about-us') + '/' + i18n.t('routes.download')).catch(() => {})
        //     } else {
        //         next();
        //     }
        // },
    },
    {
        path: '/about-us/:slug',
        alias: addEncodedRoutes([
            '/:locale/about-us/:slug',
            '/:locale/o-nas/:slug',
            '/:locale/Über-uns/:slug',
            '/:locale/quiénes-somos/:slug',
            '/:locale/tietoja-meistä/:slug',
            '/:locale/qui-sommes-nous/:slug',
            '/:locale/chi-siamo/:slug',
            '/:locale/当社について/:slug',
            '/:locale/over-ons/:slug',
            '/:locale/о-нас/:slug'
        ]),
        name: 'about',
        component: () => import('../components/Content.vue'),
        props: true,
        // beforeEnter: (to, from, next) => {
        //
        //     let newLocale = null
        //
        //     if (locales.includes(to.path.substr(0,4)) && to.path.substr(1,2) !== this.$i18n.locale) {
        //         newLocale = to.path.substr(1,2)
        //         if (newLocale) {
        //             this.$i18n.locale = newLocale
        //             useIndexStore.setLocale(newLocale)
        //         }
        //     }
        //
        //     if(this.$i18n.locale !== null && to.path.startsWith('/' + this.$i18n.locale) === false && (this.$i18n.locale !== 'en' || newLocale)) {
        //         router.push('/' + this.$i18n.locale + '/' + i18n.t('routes.about-us') + '/' + to.params.slug).catch(() => {})
        //     } else {
        //         next();
        //     }
        // },
    },
    {
        path: '/sanum/:slug',
        alias: [
            '/:locale/sanum/:slug'
        ],
        name: 'sanum',
        component: () => import('../components/Content.vue'),
        props: true,
        // beforeEnter: (to, from, next) => {
        //
        //     let newLocale = null
        //
        //     if (locales.includes(to.path.substr(0,4)) && to.path.substr(1,2) !== this.$i18n.locale) {
        //         newLocale = to.path.substr(1,2)
        //         if (newLocale) {
        //             this.$i18n.locale = newLocale
        //             useIndexStore.setLocale(newLocale)
        //         }
        //     }
        //
        //     if(this.$i18n.locale !== null && to.path.startsWith('/' + this.$i18n.locale) === false && (this.$i18n.locale !== 'en' || newLocale)) {
        //         router.push('/' + this.$i18n.locale + to.path).catch(() => {})
        //     } else {
        //         next();
        //     }
        // },
    },
    {
        path: '/cookie-policy',
        alias: addEncodedRoutes([
            '/:locale/cookie-policy',
            '/:locale/zasady-pouzivani-souboru-cookie',
            '/:locale/cookie-richtlinie',
            '/:locale/política-cookies',
            '/:locale/evästekäytäntö',
            '/:locale/politique-cookies',
            '/:locale/policy-cookie',
            '/:locale/cookieポリシー',
            '/:locale/cookiebeleid',
            '/:locale/cookie-файлы'
        ]),
        name: 'cookie-policy',
        component: () => import('../components/CookiePolicy.vue'),
        props: true,
        // beforeEnter: (to, from, next) => {
        //
        //     let newLocale = null
        //
        //     if (locales.includes(to.path.substr(0,4)) && to.path.substr(1,2) !== this.$i18n.locale) {
        //         newLocale = to.path.substr(1,2)
        //         if (newLocale) {
        //             this.$i18n.locale = newLocale
        //             useIndexStore.setLocale(newLocale)
        //         }
        //     }
        //
        //     if(this.$i18n.locale !== null && to.path.startsWith('/' + this.$i18n.locale) === false && (this.$i18n.locale !== 'en' || newLocale)) {
        //         router.push('/' + this.$i18n.locale + '/' + i18n.t('routes.cookie-policy')).catch(() => {})
        //     } else {
        //         next();
        //     }
        // },
    },
    {
        path: '/privacy-policy',
        alias: addEncodedRoutes([
            '/:locale/privacy-policy',
            '/:locale/zasady-ochrany-osobnich-udaju',
            '/:locale/datenschutzrichtlinie',
            '/:locale/política-privacidad',
            '/:locale/tietosuojakäytäntö',
            '/:locale/politique-confidentialité',
            '/:locale/informativa-privacy',
            '/:locale/プライバシーポリシー',
            '/:locale/privacybeleid',
            '/:locale/политика-конфиденциальности'
        ]),
        name: 'privacy-policy',
        component: () => import('../components/PrivacyPolicy.vue'),
        props: true,
        // beforeEnter: (to, from, next) => {
        //
        //     let newLocale = null
        //
        //     if (locales.includes(to.path.substr(0,4)) && to.path.substr(1,2) !== this.$i18n.locale) {
        //         newLocale = to.path.substr(1,2)
        //         if (newLocale) {
        //             this.$i18n.locale = newLocale
        //             useIndexStore.setLocale(newLocale)
        //         }
        //     }
        //
        //     if(this.$i18n.locale !== null && to.path.startsWith('/' + this.$i18n.locale) === false && (this.$i18n.locale !== 'en' || newLocale)) {
        //         router.push('/' + this.$i18n.locale + '/' + i18n.t('routes.privacy-policy')).catch(() => {})
        //     } else {
        //         next();
        //     }
        // },
    },
    {
        path: '/shipping',
        alias: addEncodedRoutes([
            '/:locale/shipping',
            '/:locale/doruceni',
            '/:locale/Versand',
            '/:locale/envío',
            '/:locale/toimitus',
            '/:locale/expédition',
            '/:locale/spedizione',
            '/:locale/配送',
            '/:locale/verzending',
            '/:locale/доставка'
        ]),
        name: 'shipping',
        component: () => import('../components/Shipping.vue'),
        props: true,
        // beforeEnter: (to, from, next) => {
        //
        //     let newLocale = null
        //
        //     if (locales.includes(to.path.substr(0,4)) && to.path.substr(1,2) !== this.$i18n.locale) {
        //         newLocale = to.path.substr(1,2)
        //         if (newLocale) {
        //             this.$i18n.locale = newLocale
        //             useIndexStore.setLocale(newLocale)
        //         }
        //     }
        //
        //     if(this.$i18n.locale !== null && to.path.startsWith('/' + this.$i18n.locale) === false && (this.$i18n.locale !== 'en' || newLocale)) {
        //         router.push('/' + this.$i18n.locale + '/' + i18n.t('routes.shipping')).catch(() => {})
        //     } else {
        //         next();
        //     }
        // },
    },
    {
        path: '/:locale?',
        name: 'home',
        component: () => import('../components/Home.vue'),
        // beforeEnter: (to, from, next) => {
        //
        //     if (locales.includes(to.path.substr(0,3) + '/')) {
        //         let newLocale = to.path.substr(1,2)
        //         if (newLocale) {
        //             $i18n.locale = newLocale
        //             useIndexStore.setLocale(newLocale)
        //         }
        //     }
        //     else {
        //         $i18n.locale = 'en'
        //         useIndexStore().setLocale('en')
        //     }
        //
        //     next();
        //
        // },
    },
    {
        // path: "*",
        path: "/:catchAll(.*)",
        name: "NotFound",
        component: () => import('../components/PageNotFound.vue'),
        meta: {
            requiresAuth: false
        }
    }
]





// You can pass in additional options here, but let's
// keep it simple for now.
export const router = createRouter({
    history: createWebHistory(),
    routes

})
