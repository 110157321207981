<template>
  <v-app>
    <div class="overflow-hidden">

      <v-navigation-drawer
          v-model="categoryDrawer"
          location="left"
          temporary
      >
        <v-list dense class="text-left">
          <v-btn variant="text" @click="goBackToShop"><v-icon class="mr-2">mdi-chevron-left</v-icon> Back</v-btn>
          <v-divider></v-divider>
          <v-list-subheader v-if="category" class="text-uppercase">{{ $t('categories.' + category.name) }}</v-list-subheader>
          <v-list-item
              v-for="subCat in subCategories"
              :key="subCat.slug"
              @click="navigate('sub-category', {category: category.slug, subCategory: subCat.slug})"
          >

            <v-list-item-title v-text="subCat.name"></v-list-item-title>

          </v-list-item>
        </v-list>
      </v-navigation-drawer>

      <v-navigation-drawer
          v-model="shopDrawer"
          location="left"
          temporary
      >
        <v-list dense class="text-left">
          <v-btn variant="text" @click="goBackToMenu"><v-icon class="mr-2">mdi-chevron-left</v-icon> Back</v-btn>
          <v-divider></v-divider>
          <v-list-subheader class="text-uppercase">{{ $t('headings.Find by category') }}</v-list-subheader>
          <v-list-item
              v-for="(item) in categories"
              :key="item.slug"
              @click="openCategory(item)">

            <v-list-item-title>{{ $t('categories.' + item.name) }}</v-list-item-title>

          </v-list-item>
          <v-divider></v-divider>
          <v-list-subheader class="text-uppercase">{{ $t('headings.Find by brand') }}</v-list-subheader>
          <v-list-item v-for="brand in brands" :key="brand.slug" @click="navigate('brand', {slug: brand.slug})">

            <v-list-item-title>{{brand.name}}</v-list-item-title>

          </v-list-item>
        </v-list>
      </v-navigation-drawer>

      <v-navigation-drawer
          v-model="booksDrawer"
          location="left"
          temporary
      >
        <v-list dense class="text-left">
          <v-btn variant="text" @click="goBackToMenu"><v-icon class="mr-2">mdi-chevron-left</v-icon> Back</v-btn>
          <v-divider></v-divider>
          <v-list-subheader class="text-uppercase">{{ $t('headings.Find by topic') }}</v-list-subheader>
          <v-list-item
              v-for="(item) in topics"
              :key="item.slug"
              @click="openTopic(item)">

            <v-list-item-title>{{ $t('topics.' + item.name) }}</v-list-item-title>

          </v-list-item>
        </v-list>
      </v-navigation-drawer>

      <v-navigation-drawer
          v-model="topicsDrawer"
          location="left"
          temporary
      >
        <v-list dense class="text-left">
          <v-btn variant="text" @click="goBackToBooks"><v-icon class="mr-2">mdi-chevron-left</v-icon> Back</v-btn>
          <v-divider></v-divider>
          <v-list-subheader v-if="topic" class="text-uppercase">{{ $t('topics.' + topic.name) }}</v-list-subheader>
          <v-list-item
              v-for="subTopic in subTopics"
              :key="subTopic.slug"
              @click="navigate('books-sub-topic', {topicSlug: topic.slug, subTopicSlug: subTopic.slug})"
          >

            <v-list-item-title v-text="subTopic.name"></v-list-item-title>

          </v-list-item>
        </v-list>
      </v-navigation-drawer>

      <v-navigation-drawer
          v-model="menuDrawer"
          location="left"
          temporary
      >


        <v-divider></v-divider>

        <div class="lang-dropdown mt-3 ml-3 mr-3">
          <v-select
              :items="languageArray"
              item-title="name"
              item-value="lang"
              v-model="selectedLocale"
              label="Select your language"
              @update:modelValue="onChangeLocale"
              :menu-props="{ 'maxHeight': '1000px' }"
              variant="underlined"
          >
            <template v-slot:item="{ props, item }">
              <v-list-item v-bind="props" class="select-flag">
                <template v-slot:prepend="{  }">
                  <country-flag :country='languageCodeFromLocale(item.value)' size='normal' class="mr-2 mb-0"/>
                </template>
              </v-list-item>
            </template>
            <template v-slot:selection="{ item, index }">
              <span><country-flag :country='languageCodeFromLocale(item.value)' size='normal' class="mr-0"/><span>{{ item.title }}</span></span>
            </template>
          </v-select>
        </div>

        <v-spacer></v-spacer>

        <v-list dense nav>
          <v-list-item
              @click="navigate('home')"
              prepend-icon="mdi-home"
          >
            <v-list-item-title class="text-body-1 font-weight-regular">{{ $t('menu.home') }}</v-list-item-title>
          </v-list-item>

          <v-list-group
              value="About"
              prepend-icon="mdi-information"
              no-action
          >
            <template v-slot:activator="{ props }">
              <v-list-item v-bind="props">
                <v-list-item-title class="text-body-1 font-weight-regular">{{ $t('menu.about us') }}</v-list-item-title>
              </v-list-item>
            </template>

            <v-list-item
                v-for="(item, index) in about"
                :key="index"
                @click="navigate('about', {slug: item.slug})"
                :title="$t('headings.' + item.heading)"
            >
            </v-list-item>
            <v-list-item
                key="about-downloads"
                @click="navigate('downloads')"
            >
              <v-list-item-title>{{ $t('headings.Downloads') }}</v-list-item-title>
            </v-list-item>


          </v-list-group>

          <v-list-group
              value="Remedies"
              prepend-icon="mdi-mortar-pestle"
              no-action
          >
            <template v-slot:activator="{ props }">
              <v-list-item v-bind="props">
                <v-list-item-title class="text-body-1 font-weight-regular">{{ $t('menu.remedies') }}</v-list-item-title>
              </v-list-item>
            </template>

            <v-list-item
                key="remedies-1"
            >
              <v-list-item-title><router-link :to="navigateWithLocale('remedies')">{{ $t('menu.Remedy finder') }}</router-link></v-list-item-title>
            </v-list-item>
            <v-list-item
                key="remedies-2"
            >
              <v-list-item-title><router-link :to="navigateWithLocale('remedies', 'remedy-preparation')">{{ $t('headings.Remedy preparation') }}</router-link></v-list-item-title>
            </v-list-item>
            <v-list-item
                key="remedies-3"
            >
              <v-list-item-title><router-link :to="{ name: 'remedy-buying-guide'}">{{ $t('headings.Remedy buying guide') }}</router-link></v-list-item-title>
            </v-list-item>
            <v-list-item
                key="remedies-4"
            >
              <v-list-item-title><router-link :to="navigateFormWithLocale('mother-tincture')">{{ $t('menu.Mother tinctures') }}</router-link></v-list-item-title>
            </v-list-item>
            <v-list-item
                key="remedies-5"
            >
              <v-list-item-title><router-link :to="{ name: 'collection', params: { slug: 'creams-and-ointments' }}">{{ $t('headings.Creams and Ointments') }}</router-link></v-list-item-title>
            </v-list-item>
            <v-list-item
                key="remedies-7"
            >
              <v-list-item-title><router-link :to="navigateTaxonomyWithLocale('tissue-salts')">{{ $t('menu.Tissue salts') }}</router-link></v-list-item-title>
            </v-list-item>
            <v-list-item
                key="remedies-8"
            >
              <v-list-item-title><router-link :to="navigateTaxonomyWithLocale('combinations')">{{ $t('menu.Combinations') }}</router-link></v-list-item-title>
            </v-list-item>
            <v-list-item
                key="remedies-9"
            >
              <v-list-item-title><router-link :to="navigateWithLocale('remedies', 'materiamedica')">{{ $t('headings.Materia Medica') }}</router-link></v-list-item-title>
            </v-list-item>

          </v-list-group>
          <v-list-item @click="openShop()" prepend-icon="mdi-storefront">

            <v-list-item-title class="text-body-1 font-weight-regular">{{ $t('menu.Shop') }}</v-list-item-title>

          </v-list-item>
          <v-list-item @click="openBooks()" prepend-icon="mdi-book-open-page-variant">

            <v-list-item-title class="text-body-1 font-weight-regular">{{ $t('menu.Book shop') }}</v-list-item-title>

          </v-list-item>

          <v-list-group
              value="Collections"
              prepend-icon="mdi-shape"
              no-action
          >
            <template v-slot:activator="{ props }">
              <v-list-item v-bind="props" class="text-body-1 font-weight-regular">{{ $t('menu.Featured') }}</v-list-item>
            </template>

            <v-list-item
                v-for="(item, index) in collections"
                :key="'collection-' + index"
            >
              <v-list-item-title><router-link :to="{ name: 'collection', params: { slug: item.slug }}">{{ item.name }}</router-link></v-list-item-title>
            </v-list-item>

          </v-list-group>

          <v-list-group
              value="Contact"
              prepend-icon="mdi-phone"
              no-action
          >
            <template v-slot:activator="{ props }">
              <v-list-item v-bind="props" class="text-body-1 font-weight-regular">{{ $t('menu.Contact us') }}</v-list-item>
            </template>

            <v-list-item
                key="contact-1"
            >
              <v-list-item-title><router-link :to="{ name: 'contact' }">{{ $t('menu.Contact us') }}</router-link></v-list-item-title>
            </v-list-item>
            <v-list-item
                key="contact-2"
            >
              <v-list-item-title><router-link :to="{ name: 'enquiry' }">{{ $t('menu.Secure order / payment form') }}</router-link></v-list-item-title>
            </v-list-item>
            <v-list-item
                key="contact-3"
            >
              <v-list-item-title><router-link :to="{ name: 'faqs' }">{{ $t('menu.Frequently asked questions') }}</router-link></v-list-item-title>
            </v-list-item>
            <v-list-item
                key="contact-4"
            >
              <v-list-item-title><router-link :to="navigateWithLocale('contact', 'getting-advice')">{{ $t('headings.Getting advice') }}</router-link></v-list-item-title>
            </v-list-item>
            <v-list-item
                key="contact-5"
            >
              <v-list-item-title><router-link :to="navigateWithLocale('contact', 'feedback')">{{ $t('headings.Feedback') }}</router-link></v-list-item-title>
            </v-list-item>
            <v-list-item
                key="contact-6"
            >
              <v-list-item-title><router-link :to="navigateWithLocale('contact', 'job-vacancies')">{{ $t('headings.Job vacancies') }}</router-link></v-list-item-title>
            </v-list-item>


          </v-list-group>
        </v-list>

      </v-navigation-drawer>

      <v-app-bar
          absolute
          color="#fff"
          height="131"
          class="app-bar"
          scroll-target="#scrolling-content"
      >
        <!--        <template v-slot:img="{ props }">-->
        <!--          <v-img-->
        <!--                  v-bind="props"-->
        <!--                  gradient="to top right, rgba(19,84,122,.5), rgba(128,208,199,.8)"-->
        <!--          ></v-img>-->
        <!--        </template>-->

        <!--        <template v-slot:prepend>-->
        <!--          <v-app-bar-nav-icon @click.stop="menuDrawer = !menuDrawer" class="d-md-none"></v-app-bar-nav-icon>-->
        <!--        </template>-->

        <div class="d-lg-none mt-1">
          <router-link :to="{ name: 'home'}">
            <v-img
                src="/helios-logo.png"
                eager
                width="50px"
            >
            </v-img>
          </router-link>
          <v-app-bar-nav-icon @click.stop="menuDrawer = !menuDrawer" class="d-lg-none"></v-app-bar-nav-icon>
        </div>

        <div class="d-none d-lg-block mt-1">
          <router-link :to="{ name: 'home'}">
            <v-img
                src="/helios-logo.png"
                max-width="80px"
                min-width="80px"
                eager
            >
            </v-img>
          </router-link>
        </div>



        <div class="lang-dropdown ml-12 mt-8 d-none d-lg-flex">
          <!--              <div class="mr-3 chosen-flag">-->
          <!--                <country-flag :country='languageCodeFromLocale($i18n.locale)' size='normal'/>-->
          <!--              </div>-->
          <v-select
              :items="languageArray"
              item-title="name"
              item-value="lang"
              label="Select your language"
              @update:modelValue="onChangeLocale"
              :menu-props="{ 'maxHeight': '1000px' }"
              id="countrySelect"
              v-model="selectedLocale"
              variant="underlined"
          >
            <template v-slot:item="{ props, item }">
              <v-list-item v-bind="props" class="select-flag">
                <template v-slot:prepend="{  }">
                  <country-flag :country='languageCodeFromLocale(item.value)' size='normal' class="mr-2 mb-0"/>
                </template>
              </v-list-item>
            </template>
            <template v-slot:selection="{ item, index }">
              <span><country-flag :country='languageCodeFromLocale(item.value)' size='normal' class="mr-0"/><span>{{ item.title }}</span></span>
            </template>

          </v-select>
        </div>

        <v-spacer></v-spacer>

        <v-toolbar-items class="flex-row d-none d-lg-flex pl-10 flex-auto" style="height: fit-content">
          <v-row class="d-flex align-start justify-start flex-wrap mt-2">
            <v-btn density="comfortable" :size="isSmall" variant="text" color="primary" class="mr-2 pt-2 pr-4 pb-2 pl-4" @click="navigate('home', {locale: $i18n.locale})">
              {{ $t('menu.home') }}
            </v-btn>
            <v-menu offset-y open-on-hover>
              <template v-slot:activator="{ props }">
                <v-btn
                    v-bind="props"
                    color="primary"
                    text
                    class="mr-2 pt-2 pr-4 pb-2 pl-4"
                    :size="isSmall"
                    append-icon="mdi-chevron-down"
                    density="comfortable"
                >
                  {{ $t('menu.about us') }}
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                    v-for="(item, index) in about"
                    :key="'about-' + index"
                >
                  <v-list-item-title>
                    <router-link :to="navigateWithLocale('about-us', item.slug)" class="text-decoration-none">{{ $t('headings.' + item.heading) }}</router-link>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item
                    key="about-downloads"
                >
                  <v-list-item-title>
                    <router-link :to="{ name: 'downloads' }" class="text-decoration-none">{{ $t('headings.Downloads') }}</router-link>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-menu offset-y open-on-hover>
              <template v-slot:activator="{ props }">
                <v-btn
                    color="primary"
                    text
                    v-bind="props"
                    class="mr-2 pt-2 pr-4 pb-2 pl-4"
                    :size="isSmall"
                    append-icon="mdi-chevron-down"
                    density="comfortable"
                >
                  {{ $t('menu.remedies') }}
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                    key="remedies-1"
                >
                  <v-list-item-title><router-link :to="navigateWithLocale('remedies')" class="text-decoration-none">{{ $t('menu.Remedy finder') }}</router-link></v-list-item-title>
                </v-list-item>
                <v-list-item
                    key="remedies-2"
                >
                  <v-list-item-title><router-link :to="navigateWithLocale('remedies', 'remedy-preparation')" class="text-decoration-none">{{ $t('headings.Remedy preparation') }}</router-link></v-list-item-title>
                </v-list-item>
                <v-list-item
                    key="remedies-3"
                >
                  <v-list-item-title><router-link :to="{ name:'remedy-buying-guide' }" class="text-decoration-none">{{ $t('headings.Remedy buying guide') }}</router-link></v-list-item-title>
                </v-list-item>
                <v-list-item
                    key="remedies-4"
                >
                  <v-list-item-title><router-link :to="navigateFormWithLocale('mother-tincture')" class="text-decoration-none">{{ $t('menu.Mother tinctures') }}</router-link></v-list-item-title>
                </v-list-item>
                <v-list-item
                    key="remedies-5"
                >
                  <v-list-item-title><router-link :to="{ name: 'collection', params: { slug: 'creams-and-ointments' }}" class="text-decoration-none">{{ $t('headings.Creams and Ointments') }}</router-link></v-list-item-title>
                </v-list-item>
                <v-list-item
                    key="remedies-7"
                >
                  <v-list-item-title><router-link :to="navigateTaxonomyWithLocale('tissue-salts')" class="text-decoration-none">{{ $t('menu.Tissue salts') }}</router-link></v-list-item-title>
                </v-list-item>
                <v-list-item
                    key="remedies-8"
                >
                  <v-list-item-title><router-link :to="navigateTaxonomyWithLocale('combinations')" class="text-decoration-none">{{ $t('menu.Combinations') }}</router-link></v-list-item-title>
                </v-list-item>
                <v-list-item
                    key="remedies-9"
                >
                  <v-list-item-title><router-link :to="navigateWithLocale('remedies', 'materiamedica')" class="text-decoration-none">{{ $t('headings.Materia Medica') }}</router-link></v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-btn density="comfortable" :size="isSmall" variant="text" color="primary" class="mr-2 pt-2 pr-4 pb-2 pl-4 no-underline" @click="goToShop">
              {{ $t('menu.Shop') }}
            </v-btn>
            <v-menu offset-y open-on-hover>
              <template v-slot:activator="{ props }">
                <v-btn
                    color="primary"
                    text
                    v-bind="props"
                    class="mr-2 pt-2 pr-4 pb-2 pl-4"
                    :size="isSmall"
                    append-icon="mdi-chevron-down"
                    density="comfortable"
                >
                  {{ $t('menu.Featured') }}
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                    v-for="(item, index) in collections"
                    :key="'featured-' + index"
                >
                  <v-list-item-title><router-link :to="navigateCollectionWithLocale(item.slug)" class="text-decoration-none">{{ item.name }}</router-link></v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-menu offset-y open-on-hover>
              <template v-slot:activator="{ props }">
                <v-btn
                    color="primary"
                    text
                    v-bind="props"
                    class="mr-2 pt-2 pr-4 pb-2 pl-4"
                    :size="isSmall"
                    append-icon="mdi-chevron-down"
                    density="comfortable"
                >
                  Sanum
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                    v-for="(item, index) in sanum"
                    :key="'sanum-' + index"
                >
                  <v-list-item-title>
                    <router-link :to="navigateSanumWithLocale(item.slug)">{{ $t('headings.' + item.heading) }}</router-link>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title><router-link :to="{ name: 'collection', params: { slug: 'sanum-products' }}">{{ $t('Products') }}</router-link></v-list-item-title>
                </v-list-item>
                <v-list-item>
                  <v-list-item-title><router-link :to="{ name: 'books-topic', params: { topicSlug: 'sanum' }}">{{ $t('headings.Books') }}</router-link></v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-btn density="comfortable" :size="isSmall" variant="text" color="primary" class="mr-2 pt-2 pr-4 pb-2 pl-4 no-underline" @click="goToBooks">
              {{ $t('menu.Books') }}
            </v-btn>

            <v-menu offset-y open-on-hover>
              <template v-slot:activator="{ props }">
                <v-btn
                    color="primary"
                    text
                    v-bind="props"
                    class="mr-2 pt-2 pr-4 pb-2 pl-4"
                    :size="isSmall"
                    append-icon="mdi-chevron-down"
                    density="comfortable"
                >
                  {{ $t('menu.Contact us') }}
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                    key="contact-1"
                >
                  <v-list-item-title><router-link :to="{ name: 'contact' }" class="text-decoration-none">{{ $t('menu.Contact us') }}</router-link></v-list-item-title>
                </v-list-item>
                <v-list-item
                    key="contact-2"
                >
                  <v-list-item-title><router-link :to="{ name: 'enquiry' }" class="text-decoration-none">{{ $t('menu.Secure order / payment form') }}</router-link></v-list-item-title>
                </v-list-item>
                <v-list-item
                    key="contact-3"
                >
                  <v-list-item-title><router-link :to="{ name: 'faqs' }" class="text-decoration-none">{{ $t('menu.Frequently asked questions') }}</router-link></v-list-item-title>
                </v-list-item>
                <v-list-item
                    key="contact-4"
                >
                  <v-list-item-title><router-link :to="navigateWithLocale('contact', 'getting-advice')" class="text-decoration-none">{{ $t('headings.Getting advice') }}</router-link></v-list-item-title>
                </v-list-item>
                <v-list-item
                    key="contact-5"
                >
                  <v-list-item-title><router-link :to="navigateWithLocale('contact', 'feedback')" class="text-decoration-none">{{ $t('headings.Feedback') }}</router-link></v-list-item-title>
                </v-list-item>
                <v-list-item
                    key="contact-6"
                >
                  <v-list-item-title><router-link :to="navigateWithLocale('contact', 'job-vacancies')" class="text-decoration-none">{{ $t('headings.Job vacancies') }}</router-link></v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-row>

        </v-toolbar-items>

        <div d-flex align-end flex-column>

          <div class="text-center logged-in-block d-flex align-center ml-3">
            <div class="pa-0 mt-2" id="search">
              <div>

                <v-menu style="top: 30px;" location="bottom start" offset="0" v-model="suggestions" location-strategy="connected" class="search-suggestions">
                  <v-list style="width: 450px" v-if="searchProducts.length > 0">
                    <v-list-item>
                      <v-list-item-title class="font-weight-bold">Showing {{ searchProducts.length }} suggestion<span v-if="searchProducts.length > 1">s</span> from {{ this.entries.length }} products</v-list-item-title>
                      Select below, or <v-btn variant="tonal" color="primary" @click="sendSearch" append-icon="mdi-chevron-right">show all results</v-btn>

                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item
                        v-for="product in searchProducts"
                        :key="product.slug"
                        @click="goToProduct(product)"
                    >
                      <div class="d-flex justify-start">
                        <div width="60px" class="mr-2" v-if="product.path">
                          <v-img
                              contain
                              height="60px"
                              width="60px"
                              :src="imagesHost + '/assets/product-basket/' + product.path"
                          />
                        </div>


                        <div class="text-left">

                          <v-list-item-title>{{ product.name }}</v-list-item-title>
                          <v-list-item-subtitle v-if="product.is_remedy">Remedy</v-list-item-subtitle>
                          <v-list-item-subtitle v-else-if="product.is_book">Book</v-list-item-subtitle>

                        </div>
                      </div>
                      <!--                        <v-divider inset class="mt-2 mb-2"></v-divider>-->


                    </v-list-item>

                  </v-list>
                  <template v-slot:activator="{ props }">
                    <v-text-field color="#34498e" label="Search the shop" placeholder="Start typing to search" clearable v-bind="props" ref="product-search" v-model="search" append-inner-icon="mdi-magnify" @click:append-inner="sendSearch" @keydown="$event.keyCode === 13 ? sendSearch() : false">

                    </v-text-field>
                  </template>
                </v-menu>
              </div>

              <!--                  <v-form ref="search" @submit.prevent="sendSearch">-->
              <!--                    <div class="d-flex justify-space-between">-->
              <!--                      <v-autocomplete-->
              <!--                          v-model="select"-->
              <!--                          :items="items"-->
              <!--                          :loading="isLoading"-->
              <!--                          v-model:search="search"-->
              <!--                          color="#34498e"-->
              <!--                          item-text="name"-->
              <!--                          item-value="slug"-->
              <!--                          :label="$t('headings.Search the shop')"-->
              <!--                          :placeholder="$t('placeholder.Start typing to search')"-->
              <!--                          return-object-->
              <!--                          clearable-->
              <!--                          hide-no-data-->
              <!--                          allow-overflow-->
              <!--                          v-on:keyup.enter="$event.target.blur()"-->
              <!--                      >-->
              <!--                        <template v-slot:prepend-item>-->
              <!--                          <v-list-item>-->
              <!--                            <v-list-item-title class="text-wrap">{{ $t('Scroll and select auto-suggestions below, or search all by search term') }}</v-list-item-title>-->
              <!--                          </v-list-item>-->

              <!--                          <v-divider></v-divider>-->
              <!--                        </template>-->
              <!--                        <template v-slot:item="{ parent, item }">-->

              <!--                            <v-list-item-title v-html="`${parent.genFilteredText(item.name)}`"></v-list-item-title>-->
              <!--                            <v-list-item-subtitle v-if="item.is_remedy">Remedy</v-list-item-subtitle>-->
              <!--                            <v-list-item-subtitle v-if="item.is_kit">Kit</v-list-item-subtitle>-->
              <!--                            <v-list-item-subtitle v-if="item.book">Book - ISBN: {{ item.book.isbn }}</v-list-item-subtitle>-->

              <!--                        </template>-->
              <!--                      </v-autocomplete>-->
              <!--                      <v-btn icon @click="sendSearch">-->
              <!--                        <v-icon>mdi-magnify</v-icon>-->
              <!--                      </v-btn>-->
              <!--                    </div>-->

              <!--                  </v-form>-->
            </div>

          </div>
          <div class="d-flex flex-row justify-space-between align-center ml-3 mr-0" style="margin-top: -20px !important">

            <div>
              <div class="d-flex flex-row justify-space-between align-center">
                <v-btn
                    v-if="productsCount > 0"
                    size="x-small"
                    color="success"
                    class="mr-2"
                    variant="flat"
                    @click.stop="gotToCheckout"
                >
                  <v-icon left>
                    mdi-credit-card
                  </v-icon>{{ $t('links.Checkout') }}
                </v-btn>

                <v-btn
                    icon
                    @click.stop="drawer = !drawer"
                >
                  <v-icon
                      size="x-large"
                      color="black"
                  >mdi-cart</v-icon>
                </v-btn>
                <v-badge
                    color="success"
                    text-color="white"
                    :content="productsCount"
                    class="ml-2 mb-6"
                >

                </v-badge>
              </div>



            </div>

            <div class="text-center logged-in-as-block" v-if="authenticated">

              <v-menu bottom right open-on-hover open-on-click>
                <template v-slot:activator="{ props }">
                  <div class="d-flex flex-row justify-space-between align-center">
                    <div class="black--text mt-1 mb-1 mr-1 text-right welcome d-none d-sm-inline-flex" ref="welcome-user">{{ $t('headings.Welcome') }} {{usersName}}</div>
                    <div>
                      <v-btn icon v-bind="props" ref="account">
                        <v-icon size="x-large" color="primary">mdi-account</v-icon>
                      </v-btn>
                    </div>

                  </div>

                </template>
                <v-list>
                  <v-list-item @click="navigate('account')" prepend-icon="mdi-account">

                    <v-list-item-title>{{ $t('buttons.Your account') }}</v-list-item-title>

                  </v-list-item>
                  <v-list-item ref="logout" @click="navigate('logout')" prepend-icon="mdi-logout">

                    <v-list-item-title>{{ $t('buttons.Logout') }}</v-list-item-title>

                  </v-list-item>
                </v-list>
              </v-menu>

            </div>
            <div v-else class="text-center d-flex justify-end align-center">
              <v-btn size="small" class="ma-0 mr-3" variant="tonal" color="success" @click="navigate('login')">
                <v-icon left>mdi-login</v-icon> {{ $t('buttons.Login') }}
              </v-btn>
              <v-btn size="small" class="ma-0 d-none d-sm-inline-block" variant="tonal" color="success" @click="navigate('register')">
                <v-icon left>mdi-account-plus</v-icon> {{ $t('buttons.Register') }}
              </v-btn>
              <v-badge v-if="alerts.length > 0" :content="alerts.length" style="margin-bottom: -10px" class="ml-1">
                <v-icon icon="mdi-bell-ring" size="default" color="error" class="ml-2"></v-icon>
              </v-badge>
              <v-icon v-else icon="mdi-bell-ring" size="default" color="black" class="ml-1" @click="reloadAlerts"></v-icon>
            </div>


          </div>



        </div>


      </v-app-bar>

      <v-sheet
          v-if="popover"
          style="position: absolute;top: 120px; right: 200px; z-index: 1000;"
          class="text-center ml-16 pa-0 mb-0 mt-3"
          elevation="2"
          border="0"
          rounded
      >
        <v-alert
            width="100%"
            density="compact"
            rounded
            type="success"
            class="mb-0"
        >
          {{ popoverMessage }}
        </v-alert>
      </v-sheet>

      <!--      <v-sheet-->
      <!--          v-if="popover && this.offsetTop > 1"-->
      <!--          style="position: absolute;right: 200px;z-index: 100"-->
      <!--          class="text-center ml-16 pa-0 mb-0 mt-3 d-none d-md-block"-->
      <!--          elevation="2"-->
      <!--      >-->
      <!--        <v-alert-->
      <!--            width="100%"-->
      <!--            dense-->
      <!--            text-->
      <!--            type="success"-->
      <!--            class="mb-0"-->
      <!--        >-->
      <!--          {{ popoverMessage }}-->
      <!--        </v-alert>-->
      <!--      </v-sheet>-->

      <v-sheet
          id="scrolling-content"
          class="overflow-y-auto"
          height="100%"

      >
        <v-container fluid style="height: 100vh;padding:0">
          <div style="padding-top:131px;">
            <div v-if="alerts.length > 0" class="d-xs-block d-lg-none">
              <v-alert
                  v-for="(alert, index) in alerts"
                  :key="alert.uid"
                  class="mb-3 mt-3 ml-5 mr-5"
                  type="info"
                  variant="tonal"
                  border="start"
                  close-text="Close Alert"
                  closable
                  elevation="2"
                  colored-border
                  @click:close="alertClosed(alert.uid, index)"
              >
                <div class="d-flex flex-row align-center justify-space-between">
                  <div class="text-body-1">{{ alert.title }}</div>

                  <v-btn class="ml-3" size="small" variant="tonal" color="primary" @click="openAlertModal(index)" append-icon="mdi-chevron-right">Read more</v-btn>
                </div>
              </v-alert>
            </div>
            <div v-if="alerts.length > 0" class="d-none d-lg-flex flex-row">
              <v-alert
                  v-for="(alert, index) in alerts"
                  :key="alert.uid"
                  class="mb-3 mt-3 ml-5 mr-5"
                  type="info"
                  variant="tonal"
                  border="start"
                  close-text="Close Alert"
                  closable
                  elevation="2"
                  colored-border
                  @click:close="alertClosed(alert.uid, index)"
              >
                <div class="d-flex flex-row align-center justify-space-between">
                  <div class="text-body-1 text-left">{{ alert.title }}</div>

                  <v-btn class="ml-3" size="small" variant="tonal" color="primary" @click="openAlertModal(index)" append-icon="mdi-chevron-right">Read more</v-btn>
                </div>

              </v-alert>
            </div>
            <router-view v-slot="{ Component }" v-scroll:#scrolling-content="onScroll">
              <transition name="fade">
                <component :is="Component" />
              </transition>
            </router-view>
          </div>
          <v-dialog
              v-model="alertModal"
              max-width="600"
          >
            <v-card :title="alertModalTitle">
              <v-card-text v-html="alertModalText">

              </v-card-text>
              <v-card-actions class="d-flex justify-end">
                <v-btn variant="tonal" color="primary" @click="alertModal = false">Close</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

        </v-container>
      </v-sheet>
    </div>

    <v-navigation-drawer
        ref="basketDrawer"
        v-model="drawer"
        location="right"
        :width="drawerWidth"
        temporary
        style="top: 0;height: 100%"
    >
      <BasketManage :drawer="drawer" :basketDrawerContent="basketDrawerContent" v-on:close-drawer="drawer = false" />
    </v-navigation-drawer>

    <v-snackbar
        centered multi-line
        v-model="snackbar"
        :color="snackColor"
        :timeout="snackTimeout"
        :z-index="5000"
    >
      {{ snackText }}
      <template v-slot:actions>
        <v-btn
            v-if="snackReload"
            variant="text"
            v-bind="attrs"
            @click="reloadPage"
        >
          {{ $t('buttons.Reload') }}
        </v-btn>
        <v-btn
            v-else-if="snackDismissible"
            variant="text"
            @click="snackbar = false"
        >
          {{ $t('buttons.Close') }}
        </v-btn>
      </template>

    </v-snackbar>

    <CookieAcceptDecline />

  </v-app>

</template>

<script>

//import Vue from "vue";
import VueGtag from "vue-gtag";
import axios from 'axios';
//import { store } from './store'
import BasketManage from './components/BasketManage.vue'
//import Footer from './components/Footer'
import { mapActions, mapState } from 'pinia'
import CookieAcceptDecline from './components/CookieAcceptDecline.vue'
import {localizationHeader} from "./helpers/localization-header";
import {basketService, userService} from "./services";
import * as Sentry from "@sentry/vue";
import {useIndexStore} from "./stores/index";
import {useBasketStore} from "./stores/basket";
import {useAccountStore} from "./stores/account";
import {useAlertStore} from "./stores/alert"
//import {alternateHrefLangs} from "./helpers/alternate-hreflangs";
import CountryFlag from 'vue-country-flag-next'
import {useSearchStore} from "./stores/search";
import {router} from "./helpers";
import { version } from '../package.json'

export default {
  name: 'App',
  components: {
    BasketManage,
    CookieAcceptDecline,
    CountryFlag
  },
  data () {
    return {
      basketDrawerContent: null,
      collapseOnScroll: false,
      active: false,
      attrs: '',
      categories: [],
      subCategories: [],
      topics: [],
      subTopics: [],
      brands: [],
      category: null,
      topic: null,
      snackbar: false,
      snackColor: '',
      snackText: null,
      snackDismissible: false,
      snackReload: false,
      snackTimeout: 6000,
      snack: '',
      languageArray: [
        {
          name: 'Czech',
          lang: 'cs'
        },
        {
          name: 'Dutch',
          lang: 'nl'
        },
        {
          name: 'English',
          lang: 'en'
        },
        {
          name: 'Finnish',
          lang: 'fi'
        },
        {
          name: 'French',
          lang: 'fr'
        },
        {
          name: 'German',
          lang: 'de'
        },
        {
          name: 'Italian',
          lang: 'it'
        },
        {
          name: 'Japanese',
          lang: 'ja'
        },
        {
          name: 'Russian',
          lang: 'ru'
        },
        {
          name: 'Spanish',
          lang: 'es'
        }
      ],
      offsetTop: 0,
      term: '',
      suggestions: false,
      about: [],
      sanum: [],
      remedies: [],
      showShop: false,
      descriptionLimit: 50,
      entries: [],
      isLoading: false,
      search: null,
      select: null,
      loading: false,
      collections: [],
      drawer: false,
      menuDrawer: false,
      aboutActive: false,
      shopDrawer: false,
      categoryDrawer: false,
      booksDrawer: false,
      topicsDrawer: false,
      alertModalTitle: null,
      alertModalText: null,
      alertModal: false,
      selectedLocale: ''
    }
  },
  watch: {
    '$route' (to, from) {
      if (to !== from) {
        this.snackbar = false

        axios
            .get(import.meta.env.VITE_API_HOST + '/api/release')
            .then(response => {
              console.log("release" + response.data.release)
              console.log("release" + this.release)
              if (this.compareRelease(response.data.release, this.release) === 1) {
                console.log("is new version")
                // this.reload('An newer version of this website is available.')
                window.location.reload();
              }
            })
            .catch(error => {
              console.log(error)
            })
      }
    },
    message (val) {
      if(val) {
        console.log(val)
        this.snackbar = true
        this.snackColor = this.color
        this.snackText = val
        this.snackDismissible = this.dismissible
        this.snackReload = this.isReload
        if (this.isReload) {
          this.snackTimeout = -1
        }
        // Delay clearing the message to avoid race conditions
        if (!this.isReload) {
          setTimeout(() => {
            this.clear();
          }, 5000); // Match your snackbar's display duration
        }
      }
    },
    select (val) {
      if (val) {
        this.$router.push({name: 'product', params: { slug: val.slug  }}).catch(() => {})
        this.select = ''
      }
    },
    search (val) {
      // need to force trigger auto suggest menu to open
      let check = this.searchProducts

      this.term = val.trim();

      if (this.entries.length > 0) return

      if (this.isLoading) return

      if (val) {
        this.getProductSearch()
      }
      else {
        this.suggestions = false
      }

    },
    drawer (val) {
      if (val) {
        basketService.clean(this.$t).then(() => {
          console.log('cleaned')
        }).catch(error => {
          if (error.response && [401, 419].includes(error.response.status)) {
            this.error(this.$t('You have been logged out due to inactivity'))
            userService.logout()
          }
        });
      }
      else {
        // store.dispatch('basket/resetCleaned', { root: true });
        // store.dispatch('basket/resetPriceChanges', { root: true });
        this.setBasketClosed()
      }
    },
    cleaned (val) {
      console.log('cleaned: ' + val)
      if (val) {
        console.log('basket cleaned')
        //this.drawer = true
      }
    },
    priceChanges (val) {
      console.log('cleaned: ' + val)
      if (val) {
        console.log('basket cleaned')
        //this.drawer = true
      }
    },
    basketOpen (val) {
      if (val) {
        this.drawer = val
      }
    },
  },
  computed: {
    ...mapState(useIndexStore, ['release', 'locale', 'authenticated']),
    ...mapState(useBasketStore, ['cleaned', 'priceChanges', 'popover', 'popoverMessage', 'popoverUpdate', 'popoverDelete', 'productsCount', 'basketOpen']),
    ...mapState(useAccountStore, ['usersName', 'loggedIn']),
    ...mapState(useAlertStore, ['message', 'alerts', 'type', 'color', 'isReload', 'dismissible']),
    imagesHost() {
      return import.meta.env.VITE_IMAGES_HOST;
    },
    searchProducts () {
      if (this.search === '') {
        return []
      }

      let matches = 0

      return this.items.filter(product => {
        if (
            product && this.search && product.name && product.name.toLowerCase().includes(this.search.toLowerCase())
            && matches < 10
        ) {
          matches++
          this.suggestions = true
          return product
        }
      })
    },
    isSmall () {
      if (window.innerWidth < 1264) {
        return 'small'
      }

      return 'default'
    },
    fields () {
      if (!this.model) return []

      return Object.keys(this.model).map(key => {
        return {
          key,
          value: this.model[key] || 'n/a',
        }
      })
    },
    items () {
      if (this.search) {

        return this.entries.map(entry => {
          const name = entry.name_combined.length > this.descriptionLimit
              ? entry.name_combined.slice(0, this.descriptionLimit) + '...'
              : entry.name_combined

          const slug = entry.slug

          return Object.assign({}, entry, { name, slug })
        })
      }
      else {
        return []
      }
    },
    drawerWidth() {
      if (window.innerWidth < 600) {
        return window.innerWidth
      }
      else {
        return window.innerWidth / 2
      }
    }
  },
  methods: {
    ...mapActions(useSearchStore, ['setTerm']),
    ...mapActions(useIndexStore, ['setLocale', 'setRelease', 'setConfig']),
    ...mapActions(useAlertStore, ['error', 'reload', 'clear', 'setAlerts', 'clearAlert', 'clearAlerts']),
    ...mapActions(useBasketStore, ['setBasketClosed']),
    loadConfigs() {
      this.loading = true

      const instance = axios.create({
        baseURL: import.meta.env.VITE_API_HOST
      });

      instance
          .get('/api/translation-configs/list')
          .then(response => {
            if (response.status === 200) {
              console.log(response.data);
              this.setConfig(response.data)
            }
          })
          .catch(error => {
            console.log(error)
          })
    },
    alertClosed(uid, index) {
      console.log(uid)

      let uids = localStorage.getItem("alerts")
      let newUids = [];

      if (uids) {
        newUids = uids.split(',')
      }

      if (uids && uids.includes(uid) === false) {
        newUids.push(uid)
      }
      else {
        newUids.push(uid)
      }

      this.clearAlert(index)
      localStorage.setItem("alerts", newUids)
    },
    openAlertModal(index) {
      this.alertModalTitle = this.alerts[index].title
      this.alertModalText = this.alerts[index].message
      this.alertModal = true
    },
    goToProduct(product) {
      this.suggestions = false
      this.search = ''
      this.$router.push({name: 'product', params: { slug: product.slug  }}).catch(() => {})
    },
    compareRelease(latest, current) {

      // https://getbutterfly.com/how-to-compare-version-numbers-in-javascript/
      var Versioning = {};

      Versioning.DOT_PATTERN = new RegExp("\\.");
      Versioning.NON_DIGIT_PATTERN = new RegExp("\\D");

      Versioning.compareMajor = function (version1, version2) {
        'use strict';

        return Versioning.compareLevels(version1, version2, 0);
      };

      Versioning.compareMinor = function (version1, version2) {
        'use strict';

        return Versioning.compareLevels(version1, version2, 1);
      };

      Versioning.compareRevision = function (version1, version2) {
        'use strict';

        return Versioning.compareLevels(version1, version2, 2);
      };

      /**
       * Compares two version strings. This only considers numeric components to
       * a version - all non-digit components (besides the delimiter - period)
       * will be ignored. Trailing zero components/pieces will be ignored -
       * i.e. 3.1.0 is equivalent to 3.1, but 3.10 is greater than 3.1
       *
       * @param {String} version1 First version string to compare
       * @param {String} version2 Second version string to compare
       * @param {Integer} index 0-index based number to represent the level
       *
       * @return negative if version1 < version2,
       * zero if version1 == version2,
       * positive if version1 > version2
       */
      Versioning.compareLevels = function (version1, version2, index) {
        'use strict';

        var stringLength = index + 1,
            v1 = Versioning.normalize(version1),
            v2 = Versioning.normalize(version2);

        if (v1.length > stringLength) {
          v1.length = stringLength;
        }
        if (v2.length > stringLength) {
          v2.length = stringLength;
        }

        return Versioning.cmp(v1, v2);
      };

      /**
       * Compares two version strings. This only considers numeric components to
       * a version - all non-digit components (besides the delimiter - period) will
       * be ignored. Trailing zero components/pieces will be ignored -
       * i.e. 3.1.0 is equivalent to 3.1, but 3.10 is greater than 3.1
       *
       * @param {String} version1 First version string to compare
       * @param {String} version2 Second version string to compare
       *
       * @return negative if version1 < version2,
       * zero if version1 == version2,
       * positive if version1 > version2
       */
      Versioning.compare = function (version1, version2) {
        'use strict';

        return Versioning.cmp(Versioning.normalize(version1), Versioning.normalize(version2));
      };

      /**
       * Normalizes a version string
       *
       * @param {String} version
       */
      Versioning.normalize = function (version) {
        'use strict';

        var trimmed = version ? version.replace(/^\s*(\S*(\s+\S+)*)\s*$/, "$1") : '',
            pieces = trimmed.split(Versioning.DOT_PATTERN),
            partsLength,
            parts = [],
            value,
            piece,
            num,
            i;

        for (i = 0; i < pieces.length; i += 1) {
          piece = pieces[i].replace(Versioning.NON_DIGIT_PATTERN, '');
          num = parseInt(piece, 10);

          if (isNaN(num)) {
            num = 0;
          }
          parts.push(num);
        }
        partsLength = parts.length;
        for (i = partsLength - 1; i >= 0; i -= 1) {
          value = parts[i];
          if (value === 0) {
            parts.length -= 1;
          } else {
            break;
          }
        }

        return parts;
      };

      /**
       * The return value is negative if x < y, zero if x == y and strictly positive if x > y
       *
       * @param {Array} x Array of ints
       * @param {Array} y Array of ints
       */
      Versioning.cmp = function (x, y) {
        'use strict';

        var size = Math.min(x.length, y.length),
            i;

        for (i = 0; i < size; i += 1) {
          if (x[i] !== y[i]) {
            return x[i] < y[i] ? -1 : 1;
          }
        }

        if (x.length === y.length) {
          return 0;
        }

        return (x.length < y.length) ? -1 : 1;
      };

      return Versioning.compare(latest, current);
    },
    reloadPage() {
      window.location.reload();
    },
    goToShop () {
      let route = '/' + this.$i18n.locale + '/' + this.$t('routes.shop')
      this.$router.push({path: route}).catch(() => {})
    },
    goToBooks () {
      //let route = '/' + this.$i18n.locale + '/' + this.$t('routes.books')
      let route = this.getBooksRoute()
      this.$router.push({path: route}).catch(() => {})
    },
    // onResize () {
    //   console.log(window.innerWidth)
    //   this.collapseOnScroll = window.innerWidth > 960
    // },
    openShop() {
      this.menuDrawer = false
      this.shopDrawer = true
    },
    openCategory(category) {
      this.category = category
      this.shopDrawer = false
      this.subCategories = category.sub_categories
      this.categoryDrawer = true
    },
    openBooks() {
      console.log("here")
      this.menuDrawer = false
      this.booksDrawer = true
    },
    openTopic(topic) {
      this.topic = topic
      this.booksDrawer = false
      this.subTopics = topic.sub_topics
      this.topicsDrawer = true
    },
    goBackToMenu() {
      this.shopDrawer = false
      this.menuDrawer = true
      this.booksDrawer = false
      this.topicsDrawer = false
    },
    goBackToShop() {
      this.categoryDrawer = false
      this.shopDrawer = true
    },
    goBackToBooks() {
      this.topicsDrawer = false
      this.booksDrawer = true
    },
    onScroll (e) {
      this.offsetTop = e.target.scrollTop
    },
    // resetNavigation() {
    //     for (let category in this.categories) {
    //         this.categories[category].active = false
    //     }
    // },
    navigate(route, params) {
      // for (let i in this.categories) {
      //     this.categories[i].active = false
      // }
      console.log(route)
      console.log(params)
      this.categoryDrawer = false
      this.$router.push({name: route, params: params}).catch(() => {})
    },
    gotToCheckout() {
      router.push('/' + this.$i18n.locale + '/' + this.$t('routes.shop') + '/' + this.$t('routes.checkout')).catch(() => {})
    },
    navigateWithLocale (path, slug) {
      if (slug) {
        return '/' + this.$i18n.locale + '/' + this.$t('routes.' + path) + '/' + this.$t('routes.' + slug)
      }
      else {
        return '/' + this.$i18n.locale + '/' + this.$t('routes.shop') + '/' + this.$t('routes.search') + '/' + this.$t('routes.remedies')
      }
    },
    navigateCollectionWithLocale(collection) {
      let route = '/'
      if (this.$i18n.locale !== 'en') {
        route += this.$i18n.locale + '/'
      }
      return route + this.$t('routes.collections') + '/' + collection
    },
    navigateFormWithLocale (slug) {
      return '/' + this.$i18n.locale + '/' + this.$t('routes.shop') + '/' + this.$t('routes.search') + '/' + this.$t('routes.remedies') + '/' + this.$t('routes.form') + '/' + slug
    },
    navigateTaxonomyWithLocale (slug) {
      return '/' + this.$i18n.locale + '/' + this.$t('routes.shop') + '/' + this.$t('routes.search') + '/' + this.$t('routes.remedies') + '/' + this.$t('routes.taxonomy') + '/' + slug
    },
    navigateSanumWithLocale (slug) {
      return '/' + this.$i18n.locale + '/sanum/' + slug
    },
    navigateName(route) {
      this.$router.push(route)
    },
    getShopRoute() {
      return '/' + this.$i18n.locale + '/' + this.$t('routes.shop')
    },
    getBooksRoute() {
      return '/' + this.$i18n.locale + '/' + this.$t('routes.shop') + '/' + this.$t('routes.search') + '/' + this.$t('routes.books')
    },
    getProductSearch() {
      console.log("get products search")
      this.isLoading = true
      axios
          .get(import.meta.env.VITE_API_HOST + '/api/products/all/GB')
          .then(response => {
            this.entries = response.data.data
            console.log(this.entries)
            this.isLoading = false
            //this.meta = response.data.meta
          })
          .catch(error => {
            console.log(error)
            this.isLoading = false
          })
    },
    async sendSearch() {
console.log("sending search" + this.term)
      if (this.term !== '' && /\S/.test(this.term)) {

        let term = this.term.trim()

        await this.setTerm(term)

        this.term = ''
        this.search = ''
        this.select = null
        let that = this

        setTimeout(function(){
          that.$refs["product-search"].blur()
        },1000);

        if (this.$route.name !== 'products') {
          this.$router.push({name: 'search'})
        }
      }

    },
    async onChangeLocale(lang) {

      let previousLocale = this.$i18n.locale

      this.$i18n.locale = lang

      let store = useIndexStore()
      store.$patch({ locale: lang })

      let path = this.$router.currentRoute.value.path
      let name = this.$router.currentRoute.value.name

      if (['category', 'sub-category'].includes(name)) {
        this.$router.push('/' + this.$i18n.locale + '/' + this.$t('routes.shop')).catch(() => {})
      }
      else if (['books-topic', 'books-sub-topic'].includes(name)) {
        this.$router.push('/' + this.$i18n.locale + '/' + this.$t('routes.shop') + '/' + this.$t('routes.search') + '/' + this.$t('routes.books')).catch(() => {})
      }
      else {

        let locales = ['en', 'fr', 'cs', 'nl', 'de', 'es', 'it', 'ja', 'ru', 'fi']

        if (locales.indexOf(path.substring(1, 3)) !== -1) {
          path = path.substring(4)
        }

        let splitPath = path.split("/");

        let newPath = ''

        let localesJson = await import(
            `./locales/${previousLocale}.json`
            )

        // we need to get the previous locale translations so we can get the english routes
        let routes = JSON.parse(JSON.stringify(localesJson)).routes

        for (let i in splitPath) {
          let match = false
          for (let key in routes) {
            if (routes[key] === decodeURIComponent(splitPath[i])) {
              newPath += '/' + this.$t('routes.' + key)
              match = true
              break;
            }
          }

          // allow for params not in translations
          if (match === false) {
            if (splitPath[i] !== '') {
              newPath += '/' + splitPath[i]
            }
          }
        }

        this.$router.push('/' + lang + newPath)
      }
    },
    languageCodeFromLocale(locale) {
      if (locale === 'ja') {
        return 'jp'
      }
      else if (locale === 'en') {
        return 'gb'
      }
      else if (locale === 'cs') {
        return 'cz'
      }
      else {
        return locale
      }
    },
    closeDrawer () {
      this.drawer = false
    },
    reloadAlerts() {
      localStorage.removeItem("alerts");
      this.getAlerts()
    },
    getAlerts() {
      const LocalizationInstance = axios.create({
        baseURL: import.meta.env.VITE_API_HOST,
        headers: localizationHeader()
      });

      LocalizationInstance
          .get('/api/alerts/active')
          .then(response => {
            console.log(response.data.data)

            let data = response.data.data


            if (data.length > 0) {
              //this.snack = response.data.data[0].message
              setTimeout(() => {
                let uids = localStorage.getItem("alerts")
                let newUids = [];
                let newAlerts = []

                for (let i = 0; i < data.length; i++) {

                  let newAlert = data[i]

                  let uid = data[i].uid

                  if (!uids) {
                    newAlerts.push(newAlert)
                  }
                  else if (uids && uids.includes(uid) === false) {
                    newAlerts.push(newAlert)
                  }

                  newUids.push(uid)

                }

                this.setAlerts(newAlerts)

                //localStorage.setItem("alerts", newUids);
              },1000);
            }
          })
          .catch(error => {
            console.log(error)
          })
    }
  },
  beforeCreate() {
    console.log("locale: " + this.$i18n.locale)

    let store = useIndexStore()

    let locales = ['en', 'fr', 'cs', 'nl', 'de', 'es', 'it', 'ja', 'ru', 'fi']

    // reset locale if url prefix different
    if (router.currentRoute.value.path.startsWith('/' + store.locale) === false && locales.includes(router.currentRoute.value.path.substring(1,3))) {
      let newLocale = router.currentRoute.value.path.substring(1,3)
      this.$i18n.locale = newLocale
      store.setLocale(newLocale)
    }
    else {
      this.$i18n.locale = store.locale
    }


  },
  mounted() {
    console.log("release version: " + version)
    this.setRelease(version)

    this.setBasketClosed()

    this.selectedLocale = this.$i18n.locale

    //this.basketDrawerContent = this.$refs['basketDrawer'].$el.querySelector('div.v-navigation-drawer__content');

    console.log(screen.availWidth)

    // this.onResize()
    //
    // window.addEventListener('resize', this.onResize, { passive: true })

    //this.$i18n.locale = this.locale
    // if (store.state.account.user && store.state.account.user.token) {
    //     store.commit('loggedIn')
    // }

    let cookieAccept = localStorage.getItem('vue-cookie-accept-decline-myPanel1');

    // for returning users check for cookie accept and enable gtag
    if (cookieAccept === 'accept') {
      // Vue.use(VueGtag, {
      //     config: { id: "UA-6065382-10" },
      //     enabled: true
      // });
      this.$gtag.optIn()

      // hotjar script
      if (import.meta.env.PROD) {
        console.log("before loading hotjar")
        this.$loadScript("/hotjar.js")
            .then(() => {
              // Script is loaded, do something
              console.log("loading hotjar")
            })
            .catch(() => {
              // Failed to fetch script
              console.log("failed loading hotjar")
            });
      }
    }

    if (this.loggedIn) {
      console.log("logged in")
      // basketService.sync().then(() => {
      //   let message = 'synced'
      //   let status = true
      //   store.dispatch('basket/setBasketPopover', {status, message}).then(() => {
      //     setTimeout(function () { store.dispatch('basket/resetBasketPopover') }, 3000)
      //   }).catch(error => {
      //     Sentry.setContext("Failed set basket popover on mounted", {
      //       errors: JSON.stringify(error)
      //     });
      //     Sentry.captureException(error);
      //   });
      // }).catch(error => {
      //   console.log(error)
      //   if (error.response && [401, 419].includes(error.response.status)) {
      //     store.dispatch('alert/error', 'You have been logged out due to inactivity', { root: true });
      //     userService.logout()
      //     this.$router.push({name: 'login'}).catch(() => {})
      //   }
      // })
    }

    axios
        .get(import.meta.env.VITE_API_HOST + '/api/collections')
        .then(response => {
          this.collections = response.data.data
          this.meta = response.data.meta
        })
        .catch(error => {
          console.log(error)
        })

    axios
        .get(import.meta.env.VITE_API_HOST + '/api/content/about')
        .then(response => {
          console.log(response.data.data)
          this.about = response.data.data
        })
        .catch(error => {
          console.log(error)
        })

    axios
        .get(import.meta.env.VITE_API_HOST + '/api/content/sanum')
        .then(response => {
          this.sanum = response.data.data
        })
        .catch(error => {
          console.log(error)
        })

    axios
        .get(import.meta.env.VITE_API_HOST + '/api/categories')
        .then(response => {
          this.categories = response.data.data
        })
        .catch(error => {
          console.log(error)
        })

    axios
        .get(import.meta.env.VITE_API_HOST + '/api/topics')
        .then(response => {
          this.topics = response.data.data
        })
        .catch(error => {
          console.log(error)
        })

    axios
        .get(import.meta.env.VITE_API_HOST + '/api/brands')
        .then(response => {
          this.brands = response.data.data
        })
        .catch(error => {
          console.log(error)
        })

    this.getAlerts()

    this.loadConfigs()
  }
}
</script>

<style>
.search-suggestions .v-overlay__content {
  top: 38px !important;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
  margin-top: 0;
}
.app-bar {
  z-index: 2;
}
.v-toolbar.v-toolbar--absolute {
  z-index: 3 !important;
}
#content {
  margin-top: 28px;
}

a {
  text-decoration: none;
}
a:not(.contains-btn):not(.v-breadcrumbs-item) {
  color: #0d47a1;
}
a:hover:not(.contains-btn) {
  text-decoration: underline;
}
a:active, a:visited {
  text-decoration: none;
}

.v-toolbar__content {
  align-items: flex-start !important;
  padding: 4px 16px;
}

.v-toolbar__items {
  height: auto !important;
}

.v-application p {
  margin-bottom: 1em;
}

.v-application ul {
  margin-left: 1em;
  margin-bottom: 1em;
  margin-inline-start: 1em;
}

@media only screen and (max-width: 599px) {
  #search {
    width: 260px;
  }
}
@media only screen and (min-width: 600px) {
  #search {
    width: 450px;
  }
  .welcome {
    max-width: 200px;
    line-height: 1.3em
  }
}
@media only screen and (min-width: 1264px) {
  #search {
    width: 450px;
  }
  .welcome {
    max-width: 200px;
    line-height: 1.5em
  }
}

.v-card__title {
  word-break: break-word !important;
}

.menu-drawer .v-list-item__title:first-letter {
  text-transform: uppercase;
}

.select-flag img:not(.md-image) {
  height: 30px !important;
}

.chosen-flag {
  width: 50px;
  max-height: 60px;
}

.text-decoration-none {
  color: #34498e !important;
}

.text-decoration-none:hover {
  text-decoration: underline !important;
  color: #34498e !important;
}

.no-underline:hover {
  text-decoration: none !important;
}
.no-underline.v-btn--active:not(:hover):before {
  background-color: transparent;
}

.v-list--density-compact .v-list-item, .v-list-item--density-compact {
  min-height: 35px !important;
}
.v-list--density-compact .v-list-item .v-list-item__content {
  padding: 4px 0 !important;
}
.v-list-item--density-compact.v-list-item--one-line {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.v-list--density-compact .v-list-group {
  padding-inline-start: 16px !important;
  padding-inline-end: 16px !important;
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.v-alert__content .text-body-1 p {
  margin-bottom: 0;
}

.lang-dropdown {
  min-width: 220px;
}

/*.basket-drawer {*/
/*  width: 50% !important*/
/*}*/

.v-navigation-drawer__content .v-list-item-title:first-letter {
  text-transform: uppercase;
}

.v-app-bar.v-toolbar {
  /*left: 0px !important;*/
  /*width: 100% !important;*/
}

.v-alert__append, .v-alert__close {
  margin-inline-start: 10px !important
}

</style>
